import { Box, Button, Stack, styled } from "@mui/material";
import { useAuth } from "../../contexts/auth";
import { SearchResultItem } from "../../data-access/search";
import { FileIconDisplayNameOnly } from "../FileIconDisplay";
import FolderIcon from "@mui/icons-material/Folder";

const HoverBox = styled(Box)`
  margin: 5px;
  cursor: pointer;
  & :hover {
    background-color: #efefef;
  }
`;

const SearchItem: React.FC<{
  searchResultItem: SearchResultItem;
  onClick: (searchResultItem: SearchResultItem) => void;
  onFolderClick: (searchResultItem: SearchResultItem) => void;
}> = ({ searchResultItem, onClick, onFolderClick }) => {
  const authContext = useAuth();

  const pathTokens = searchResultItem.path_tokens;

  const name = pathTokens[pathTokens.length - 1];

  let companyIds: string[] = [];
  if (authContext?.companies != null) {
    companyIds = authContext?.companies.map((x) => x.id) || [];
  }

  const pathTokensWithoutName = pathTokens.slice(0, pathTokens.length - 1);

  let filePath = pathTokensWithoutName.join("/");
  if (companyIds.includes(pathTokens[0])) {
    const tokensWithoutCompanyId = pathTokensWithoutName.slice(1);
    filePath = tokensWithoutCompanyId.join("/");
  }

  return (
    <Stack direction={"row"} justifyContent={"space-between"}>
      <HoverBox flexGrow={1} onClick={() => onClick(searchResultItem)}>
        <Stack direction={"row"} alignItems={"center"} sx={{ padding: "0px 5px" }}>
          <FileIconDisplayNameOnly fileName={name}></FileIconDisplayNameOnly>
          <Stack direction={"column"} sx={{ padding: "15px" }}>
            <Box>
              <b>{name}</b>
            </Box>
            <span>{`/${filePath}`}</span>
          </Stack>
        </Stack>
      </HoverBox>
      <Button onClick={() => onFolderClick(searchResultItem)}>
        <FolderIcon></FolderIcon>
      </Button>
    </Stack>
  );
};

export default SearchItem;
