import { Alert, Button, Card, CardContent, Stack, TextField } from "@mui/material";
import React from "react";
import { useForm } from "react-hook-form";
import CenteredContent from "../components/CenteredContent";
import { supabaseClient } from "../util/supabase";
import { Link, useNavigate } from "react-router-dom";

export interface LoginForm {
  email: string;
  password: string;
}
const LoginPage: React.FC = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginForm>();
  const navigate = useNavigate();

  async function onSubmit(formData: LoginForm) {
    try {
      const {
        data: { user },
        error,
      } = await supabaseClient.auth.signInWithPassword({
        email: formData.email,
        password: formData.password,
      });
      if (user) {
        navigate("/");
      } else if (error) {
        console.error(error);
        alert(error.message);
      }
    } catch (e: any) {
      alert("An error occurred");
      console.error(e);
    }
  }

  return (
    <CenteredContent>
        <Card sx={{ marginTop: "30px" }}>
          <CardContent>
            <Stack direction={"column"} margin={6} spacing={6}>
              <img
                src="/img/BlueGoldLandscape.svg"
                width={"400px"}
                style={{ margin: "15px 0px 0px 0px" }}
              ></img>
              <Stack spacing={3}>
                <TextField {...register("email")} label="Email" variant="outlined" />
                {errors.email && <Alert severity="error">{errors.email?.message}</Alert>}
                <TextField
                  type="password"
                  {...register("password")}
                  label="Password"
                  variant="outlined"
                />
                {errors.password && <Alert severity="error">{errors.password?.message}</Alert>}
                <Button variant="contained" color={"primary"} onClick={handleSubmit(onSubmit)}>
                  Login
                </Button>
                <hr />
                <Link to={"/forgotpassword"}>Forgot password?</Link>
              </Stack>
            </Stack>
          </CardContent>
        </Card>
    </CenteredContent>
  );
};

export default LoginPage;
