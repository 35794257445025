import { Grid } from "@mui/material";
import React from "react";
import DirTile from "./DirTile";
import { FileObjectWithSource } from "../data-access/directory";

interface DisplayGridProps {
  dirs: FileObjectWithSource[];
  path: string;
}
const DisplayGrid: React.FC<DisplayGridProps> = ({ dirs, path }) => {
  return (
    <Grid container spacing={2}>
      {dirs?.map((dir, i) => (
        <DirTile key={i} dirModel={dir} path={path}></DirTile>
      ))}
    </Grid>
  );
};

export default DisplayGrid;
