import { Alert, Button, Stack, TextField } from "@mui/material";
import CompanyModel from "../../../models/CompanyModel";
import { useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import { updateCompanyDetails } from "../../../data-access/company";
import CompanyLogoUpload from "./CompanyLogoUpload";

type Props = {
  company: CompanyModel;
  onClose: () => void;
};

const rules: { [K in keyof CompanyModel]?: any } = {
  name: {
    required: "Please enter a name for the company",
  },
};

const CompanyDetailsEdit = ({ company, onClose }: Props) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<CompanyModel>({ defaultValues: company });

  const queryClient = useQueryClient();

  const updateCompanyMutation = useMutation({
    mutationFn: updateCompanyDetails,
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries({ queryKey: [""] });
      onClose();
    },
    onError: (error, variables) => {
      alert("Something went wrong");
    },
  });

  async function onSubmit(values: CompanyModel) {
    updateCompanyMutation.mutate(values);
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack direction={"column"} gap={1}>
        <CompanyLogoUpload
          companyId={company.id}
          logoFilename={company.logo_path}
        ></CompanyLogoUpload>

        <TextField label="Company Name" {...register("name", rules.name)}></TextField>
        {errors.name && <Alert severity={"error"}>{errors.name}</Alert>}

        <TextField label="Trading Name" {...register("trading_name")}></TextField>
        {errors.trading_name && <Alert severity={"error"}>{errors.trading_name}</Alert>}

        <TextField label="Phone Number" {...register("phone_number")}></TextField>
        {errors.phone_number && <Alert severity={"error"}>{errors.phone_number}</Alert>}

        <TextField label="Contact Name" {...register("contact_name")}></TextField>
        {errors.contact_name && <Alert severity={"error"}>{errors.contact_name}</Alert>}

        <TextField label="Contact Email" {...register("contact_email")}></TextField>
        {errors.contact_email && <Alert severity={"error"}>{errors.contact_email}</Alert>}

        <TextField label="NZBN" {...register("nzbn")}></TextField>
        {errors.nzbn && <Alert severity={"error"}>{errors.nzbn}</Alert>}

        <TextField label="Office Address" {...register("office_address")}></TextField>
        {errors.office_address && <Alert severity={"error"}>{errors.office_address}</Alert>}

        <TextField label="Postal Address" {...register("postal_address")}></TextField>
        {errors.postal_address && <Alert severity={"error"}>{errors.postal_address}</Alert>}

        <TextField label="IRD Number" {...register("ird_number")}></TextField>
        {errors.ird_number && <Alert severity={"error"}>{errors.ird_number}</Alert>}

        <TextField label="Web Address" {...register("web_address")}></TextField>
        {errors.web_address && <Alert severity={"error"}>{errors.web_address}</Alert>}

        <TextField label="LinkedIn" {...register("social_linkedin")}></TextField>
        {errors.social_linkedin && <Alert severity={"error"}>{errors.social_linkedin}</Alert>}

        <TextField label="Facebook" {...register("social_facebook")}></TextField>
        {errors.social_facebook && <Alert severity={"error"}>{errors.social_facebook}</Alert>}

        <TextField label="X (Twitter)" {...register("social_twitter")}></TextField>
        {errors.social_twitter && <Alert severity={"error"}>{errors.social_twitter}</Alert>}

        <TextField label="Instagram" {...register("social_instagram")}></TextField>
        {errors.social_instagram && <Alert severity={"error"}>{errors.social_instagram}</Alert>}

        <Stack direction={"row"} justifyContent={"end"}>
          <Button onClick={onClose}>Cancel</Button>
          <Button type={"submit"} variant={"contained"}>
            Save
          </Button>
        </Stack>
      </Stack>
    </form>
  );
};

export default CompanyDetailsEdit;
