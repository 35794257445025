import { Alert, Box, Stack, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { useQuery } from "react-query";
import { getUsersForCompany } from "../data-access/admin";
import CompanyUser from "../types/CompanyUser";
import InviteUserButton from "./InviteUserButton";
import CompanyModel from "../../../models/CompanyModel";
import { FileObjectWithSource, getTopLevelDirectories } from "../../../data-access/directory";
import PermissionsForUser from "./permissions/PermissionsForUser";
import { getStoragePermissionsForCompanyUsers } from "../data-access/permissions";
import StoragePermissionModel from "../../../models/StoragePermissionModel";

type CompanyUserListProps = {
  company: CompanyModel;
};

const CompanyUserList = ({ company }: CompanyUserListProps) => {
  const {
    data: companyUserData,
    error,
    isLoading,
  } = useQuery<CompanyUser[]>(["users-for-company", company.id], () =>
    getUsersForCompany(company.id)
  );

  const {
    data: directories,
    isLoading: dirsLoading,
    error: dirsError,
  } = useQuery(["top-level-directories"], () => getTopLevelDirectories());

  const {
    data: permissions,
    isLoading: permissionsLoading,
    error: permissionsError,
  } = useQuery(["user-permissions-for-company", company.id], () =>
    getStoragePermissionsForCompanyUsers(company.id)
  );

  if (error != null) return <Alert severity="error">Something went wrong</Alert>;

  if (isLoading) return <span>Loading...</span>;

  return (
    <Stack direction="column">
      <Stack direction="row" alignItems={"center"} justifyContent={"space-between"}>
        <h3>Users</h3>
        <InviteUserButton company={company}></InviteUserButton>
      </Stack>
      <Box sx={{ width: "750px", overflowY: "scroll" }}>
        <Table sx={{ maxWidth: "100%" }}>
          <TableHead>
            <TableRow>
              <TableCell>
                <b>Email</b>
              </TableCell>
              {directories && (
                <>
                  {directories.map((dir) => (
                    <TableCell key={dir.name}>{dir.name}</TableCell>
                  ))}
                </>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {companyUserData &&
              companyUserData.map((companyUser) => {
                const permissionsForCurrentUser = permissions?.filter(
                  (p) => p.user_id === companyUser.id
                );
                return (
                  <UserRow
                    key={companyUser.email}
                    companyUser={companyUser}
                    company={company}
                    directories={directories}
                    permissionsForCurrentUser={permissionsForCurrentUser}
                  ></UserRow>
                );
              })}
          </TableBody>
        </Table>
      </Box>
    </Stack>
  );
};

type UserRowProps = {
  companyUser: CompanyUser;
  company: CompanyModel;
  directories?: FileObjectWithSource[];
  permissionsForCurrentUser?: StoragePermissionModel[];
};
const UserRow = ({
  companyUser,
  company,
  directories,
  permissionsForCurrentUser,
}: UserRowProps) => {
  return (
    <TableRow key={companyUser.id}>
      <TableCell>{companyUser.email}</TableCell>
      <PermissionsForUser
        user={companyUser}
        company={company}
        directories={directories}
        permissions={permissionsForCurrentUser}
      ></PermissionsForUser>
    </TableRow>
  );
};

export default CompanyUserList;
