import { Box, Button, Stack } from "@mui/material";
import CompanyModel from "../../../models/CompanyModel";
import CompanyUserList from "./CompanyUserList";
import { useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import CompanyDetailsEdit from "./CompanyDetailsEdit";
import CompanyDetailsReadOnly from "./CompanyDetailsReadOnly";

type CompanyDetailsProps = {
  selectedCompany: CompanyModel;
};

export const NoCompanySelected = () => {
  return (
    <Box sx={{ padding: "20px" }}>
      <span style={{ color: "grey" }}>Select a company to view details</span>
    </Box>
  );
};

const CompanyDetails = ({ selectedCompany }: CompanyDetailsProps) => {
  const [edit, setEdit] = useState(false);

  if (selectedCompany == null) {
    return <NoCompanySelected></NoCompanySelected>;
  }

  return (
    <Stack direction={"column"} sx={{ padding: "20px", width: "100%" }}>
      <Stack direction={"row"}>
        <h1>{selectedCompany.name}</h1>
        {!edit && (
          <Button onClick={(e) => setEdit(true)}>
            <EditIcon></EditIcon>
          </Button>
        )}
      </Stack>
      {edit && (
        <CompanyDetailsEdit
          company={selectedCompany}
          onClose={() => setEdit(false)}
        ></CompanyDetailsEdit>
      )}
      {!edit && <CompanyDetailsReadOnly company={selectedCompany}></CompanyDetailsReadOnly>}
      <hr style={{ width: "100%" }} />
      <CompanyUserList company={selectedCompany}></CompanyUserList>
    </Stack>
  );
};

export default CompanyDetails;
