import { Button, TextField } from "@mui/material";
import { useState } from "react";
import { useToast } from "../../contexts/toastContext";
import { supabaseClient } from "../../util/supabase";

type Props = {
  onComplete: () => void;
  email: string;
};

const EnterOtp = ({ onComplete, email }: Props) => {
  const toast = useToast();
  const [password, setPassword] = useState("");

  async function onSubmit() {
    const {
      data: { session },
      error,
    } = await supabaseClient.auth.verifyOtp({
      email,
      token: password,
      type: "email",
    });

    if (error != null) {
      toast.error("Something went wrong");
      console.error(error);
      return;
    }

    toast.success("Check your email");
    onComplete();
  }

  return (
    <>
      <p>
        We have sent a 6 digit code to your email, please enter the code to continue resetting your
        password
      </p>
      <TextField
        placeholder="6 digit number from email"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      ></TextField>
      <Button variant="contained" onClick={onSubmit}>
        Submit
      </Button>
    </>
  );
};

export default EnterOtp;
