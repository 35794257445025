import { Button, Dialog, DialogActions, DialogContent } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { ToastContext } from "../contexts/toastContext";
import { FileObjectWithSource } from "../data-access/directory";
import StorageBucket from "../models/StorageBucket";
import { downloadFile, getSignedUrlForFile, getTrimmedBaseUrl } from "../util/files";
import OfficeDocViewer from "./OfficeDocViewer";

const DocViewer: React.FC<{
  fileName: string;
  path: string[];
  source: StorageBucket;
  selectedCompanyId: string | undefined;
  open: boolean;
  setOpen: (newValue: boolean) => void;
}> = ({ fileName, path, source, selectedCompanyId, open, setOpen }) => {
  const [docUrl, setDocUrl] = useState<string | null>(null);

  useEffect(() => {
    if (open) {
      openFileViewer();
    }
  }, [fileName, path, selectedCompanyId, open]);

  async function openFileViewer() {
    try {
      const trimmedPath = getTrimmedBaseUrl(path);
      const url = await getSignedUrlForFile(fileName, trimmedPath, source, selectedCompanyId);
      setDocUrl(url);
    } catch (ex: any) {
      console.error(ex);
      alert("error");
    }
  }

  async function onDownloadFile() {
    const trimmedPath = getTrimmedBaseUrl(path);
    await downloadFile(true, fileName, trimmedPath, source, selectedCompanyId);
  }

  return (
    <Dialog open={open} maxWidth={"lg"}>
      <DialogContent>
        <OfficeDocViewer docUrl={docUrl}></OfficeDocViewer>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onDownloadFile()} color="success" variant={"contained"}>
          Download
        </Button>
        <Button onClick={() => setOpen(false)}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default DocViewer;
