import { styled, MenuItem, Select, SelectChangeEvent, Stack, Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useAuth } from "../contexts/auth";
import { useNavigate } from "react-router";
import { getCompanyLogoUrlIfExists } from "../features/admin/data-access/companyLogo";

const StyledSelect = styled(Select)`
  margin: 5px;
  width: 300px;
  .MuiSelect-select {
    background-color: white;
  }
`;

interface CompanyRecord {
  id: string;
  name: string;
  created_at: string;
}
const CompanySelection: React.FC = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  const [companyImageMap, setCompanyImageMap] = useState<{ [companyId: string]: string }>({});

  function onSelectedCompanyChanged(event: SelectChangeEvent<unknown>, child: React.ReactNode) {
    const selectedCompany = auth?.companies?.find((x) => x.id === event.target.value);
    if (selectedCompany && auth.setSelectedCompany) {
      auth.setSelectedCompany(selectedCompany);
      navigate("/docs");
    }
  }

  useEffect(() => {
    async function loadImages() {
      if (auth.companies != null) {
        const urlObject: { [key: string]: string } = {};
        for (let company of auth.companies) {
          if (company.logo_path == null) continue;

          const logoUrl = await getCompanyLogoUrlIfExists(company.logo_path);
          urlObject[company.id] = logoUrl || "";
        }

        setCompanyImageMap(urlObject);
      }
    }
    loadImages();
  }, [auth.companies]);

  return (
    <>
      {auth?.selectedCompany && auth?.companies && (
        <StyledSelect value={auth?.selectedCompany?.id} onChange={onSelectedCompanyChanged}>
          {auth?.companies.map((company, i) => (
            <MenuItem value={company.id} key={i}>
              <Stack
                direction={"row"}
                flexGrow={1}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <img src={companyImageMap[company.id]} style={{ maxHeight: "30px", width: "auto", maxWidth: "60px"}}></img>
                <Box>{company.name}</Box>
              </Stack>
            </MenuItem>
          ))}
        </StyledSelect>
      )}
    </>
  );
};

export default CompanySelection;
