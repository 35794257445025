import React, { useEffect, useState } from "react";
import UpdatePassword from "../components/UpdatePassword";

const ResetPasswordPage: React.FC = () => {
  const [accessToken, setAccessToken] = useState<string | undefined>(undefined);

  useEffect(() => {
    const parsedHash = new URLSearchParams(
      window.location.hash.substring(1) // skip the first char (#)
    );

    if (parsedHash.get("type") === "recovery") {
      const token = parsedHash.get("access_token");
      if (token != null) {
        setAccessToken(token);
      }
    }
  }, [window.location]);

  return (
    <>
      {!accessToken && <h3>Processing...</h3>}
      {accessToken && <UpdatePassword access_token={accessToken}></UpdatePassword>}
    </>
  );
};

export default ResetPasswordPage;
