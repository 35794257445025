// import React from 'react';
// import { useRemirror } from '@remirror/react';
// import { Remirror } from '@remirror/react';
// import { LinkExtension } from 'remirror/extensions';

import React from "react";
import { Remirror, useRemirror, EditorComponent, ThemeProvider } from "@remirror/react";
import {
  BoldExtension,
  HeadingExtension,
  ItalicExtension,
  OrderedListExtension,
  BulletListExtension,
  LinkExtension,
} from "remirror/extensions";
import EditSaveButtons from "./EditSaveButtons";
import {
  RichTextRepoType,
  TextContentRecord,
  upsertTextContentForPath,
} from "../data-access/text-content";
import { wysiwygPreset } from "remirror/extensions";
import RichTextToolbar from "./RichTextToolbar";
import { AllStyledComponent } from "@remirror/styles/emotion";
import FloatingLinkToolbar from "./FloatingLinkToolbar";
import { Box } from "@mui/material";

interface RichTextEditProps {
  path: string;
  textContent: TextContentRecord | undefined;
  editable: boolean;
  type: RichTextRepoType;
  setEditable: (newValue: boolean) => void;
  textContentUpdated: (newValue: TextContentRecord) => void;
  name: string;
}

const RichTextEdit: React.FC<RichTextEditProps> = ({
  textContent,
  editable,
  type,
  setEditable,
  path,
  textContentUpdated,
  name
}) => {
  const onEdit = () => {
    setEditable(true);
  };

  const onCancel = () => {
    setEditable(false);
  };

  const onSave = async () => {
    try {
      setEditable(false);
      const newContent = manager.view.state.toJSON();
      // if (!jsonContent) return;
      if (newContent) {
        const documentJsonString = JSON.stringify(newContent.doc);
        const updatedRecord = await upsertTextContentForPath(
          path,
          documentJsonString,
          type,
          textContent?.id
        );
        if (updatedRecord && updatedRecord.length > 0) {
          textContentUpdated(updatedRecord[0]);
        }
      }
    } catch (e: any) {
      alert("Saving failed");
      console.error(e);
    }
  };

  const initialContent = textContent?.content ? JSON.parse(textContent?.content) : undefined;

  const { manager, state } = useRemirror({
    extensions: () => [
      new BoldExtension(),
      new ItalicExtension(),
      new HeadingExtension(),
      new BulletListExtension(),
      new OrderedListExtension(),
      new LinkExtension({ autoLink: true }),
    ],
    content: initialContent,
    selection: "start",
    stringHandler: "html",
  });

  // const { manager, state } = useRemirror({
  //     extensions: () => [new LinkExtension({ autoLink: true })],
  // });

  return (
    <AllStyledComponent>
      <ThemeProvider>
        <Box sx={{ border: "lightgrey 2px solid", padding: "10px", borderRadius: "10px"}}>
          <EditSaveButtons
            name={name}
            editMode={editable}
            onSave={onSave}
            onEdit={onEdit}
            onCancel={onCancel}
          ></EditSaveButtons>
          <Remirror manager={manager} initialContent={state} editable={editable}>
            {editable && <RichTextToolbar></RichTextToolbar>}
            <FloatingLinkToolbar />
            <EditorComponent></EditorComponent>
          </Remirror>
        </Box>
      </ThemeProvider>
    </AllStyledComponent>
  );
};

export default RichTextEdit;
