import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
  Tooltip,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useContext, useState } from "react";
import { addFavourite } from "../../data-access/user-favourites";
import { ToastContext, useToast } from "../../contexts/toastContext";
import { useForm } from "react-hook-form";

const urlRegex =
  /^(http(s):\/\/.)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/;

type Props = {
  reload: () => void;
};

type FormInputs = {
  name: string;
  url: string;
};

const validation = {
  name: {
    required: "Choose a name for your link",
  },
  url: {
    required: "Website address (URL) is required",
    pattern: {
      value: urlRegex,
      message: "Invalid address",
    },
  },
};

const AddFavouriteModal = ({ reload }: Props) => {
  const [showAddModal, setShowAddModal] = useState(false);

  const toast = useToast();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormInputs>();

  async function onSubmit(values: FormInputs) {
    try {
      await addFavourite(values.name, values.url);
      setShowAddModal(false);
      toast?.success("Link added");
      await reload();
    } catch (ex) {
      toast?.error("Something went wrong");
    }
  }

  return (
    <>
      <Button variant="outlined" size="small" onClick={(e) => setShowAddModal(true)}>
        <Tooltip title="Add a link to a website">
          <AddIcon></AddIcon>
        </Tooltip>
      </Button>
      <Dialog open={showAddModal} fullWidth={true} maxWidth={"sm"}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle>Add Link</DialogTitle>
          <DialogContent>
            <Stack direction="column" rowGap={2}>
              <TextField id="name" label="Name" {...register("name", validation.name)}></TextField>
              {errors.name && <Alert severity="error">{errors.name.message}</Alert>}
              <TextField label="Url" {...register("url", validation.url)}></TextField>
              {errors.url && <Alert severity="error">{errors.url.message}</Alert>}
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button onClick={(e) => setShowAddModal(false)}>Cancel</Button>
            <Button variant="contained" type={"submit"}>
              Save
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

export default AddFavouriteModal;
