import { User } from "@supabase/supabase-js";
import React, { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router";
import CompanyModel from "../models/CompanyModel";
import { LoginForm } from "../pages/LoginPage";
import { supabaseClient } from "../util/supabase";
import StoragePermissionModel from "../models/StoragePermissionModel";
import { useToast } from "./toastContext";

interface AuthContextData {
  signIn?: (data: LoginForm) => void;
  signOut?: () => void;
  user?: User | null;
  companies?: CompanyModel[];
  permissions?: StoragePermissionModel[];
  setPermissions?: (permissions: StoragePermissionModel[]) => void;
  selectedCompany?: CompanyModel;
  setSelectedCompany?: (company: CompanyModel) => void;
  isAdmin?: boolean;
}

const ADMIN_COMPANY_ID = "aa111111-222b-33cc-4444-111111111111";

const AuthContext = React.createContext<AuthContextData>({});

export const AuthProvider: React.FC = ({ children }) => {
  const [user, setUser] = useState<User | null>();
  const [loading, setLoading] = useState(true);
  const [companies, setCompanies] = useState<CompanyModel[]>([]);
  const [selectedCompany, setSelectedCompany] = useState<CompanyModel | undefined>(undefined);
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const [permissions, setPermissions] = useState<StoragePermissionModel[]>([]);
  const toast = useToast();

  const navigate = useNavigate();

  useEffect(() => {
    console.log("auth provider mounted");
    async function loadUser() {
      // Check active sessions and sets the user
      const {
        data: { session },
        error,
      } = await supabaseClient.auth.getSession();

      console.log("getSession", session);

      if (error != null) {
        toast.error("Something went wrong");
        console.log(error);
        return;
      }

      setUser(session?.user ?? null);
      setLoading(false);

      if (session?.user) {
        getCompanyData(session?.user);
        getUserPermissions(session.user);
      }
    }

    loadUser();

    // Listen for changes on auth state (logged in, signed out, etc.)
    const {
      data: { subscription },
    } = supabaseClient.auth.onAuthStateChange(async (event, session) => {
      const hashParams = new URLSearchParams(window.location.hash.replace("#", ""));

      const type = hashParams?.get("type");
      const accessToken = hashParams?.get("access_token");

      if (type === "recovery" && accessToken != null) {
        navigate(`/resetpassword#access_token=${accessToken}&type=recovery`);
      }

      setUser(session?.user ?? null);
      if (session?.user) {
        await getCompanyData(session.user);
        await getUserPermissions(session.user);
      }
      setLoading(false);
    });

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  async function getCompanyData(user: User) {
    const { data, error } = await supabaseClient.from("company").select();
    if (data) {
      if (data.length === 0) {
        alert(
          "You aren't a member of any companies in CompanyHQ, please contact your administrator or CompanyHQ support"
        );
        await supabaseClient.auth.signOut();
        return;
      }
      setCompanies(data as CompanyModel[]);
      const previouslySelectedCompanyId = getPreviousSelectedCompanyFromLocalStorage();
      if (previouslySelectedCompanyId != null) {
        const previouslySelectedCompany = data.find(
          (company) => company.id === previouslySelectedCompanyId
        );
        if (previouslySelectedCompany != null) {
          setSelectedCompany(previouslySelectedCompany);
        } else {
          setSelectedCompany(data[0]);
        }
      } else {
        setSelectedCompany(data[0]);
      }
      checkIsAdmin(data);
    } else {
      console.error(error);
    }
  }

  async function getUserPermissions(user: User) {
    const { data, error } = await supabaseClient
      .from("storage_permission")
      .select();
    if (error != null) {
      console.error(error);
      throw Error("Failed to load permissions for user");
    }
    console.log("permissions", data);
    setPermissions(data as StoragePermissionModel[]);
  }

  function getPreviousSelectedCompanyFromLocalStorage() {
    const previouslySelectedCompanyId = localStorage.getItem("selected-company-id");
    return previouslySelectedCompanyId || undefined;
  }

  function saveSelectedCompanyIdToLocalStorage(companyId: string) {
    localStorage.setItem("selected-company-id", companyId);
  }

  function checkIsAdmin(companies: CompanyModel[]) {
    const adminCompany = companies.find((x) => x.id === ADMIN_COMPANY_ID);
    setIsAdmin(adminCompany != null);
  }

  function onSetSelectedCompany(company: CompanyModel) {
    saveSelectedCompanyIdToLocalStorage(company.id);
    setSelectedCompany(company);
  }

  // Will be passed down to Signup, Login and Dashboard components
  const value = {
    signIn: (data: LoginForm) => supabaseClient.auth.signInWithPassword(data),
    signOut: () => supabaseClient.auth.signOut(),
    user,
    companies,
    permissions,
    setPermissions,
    selectedCompany,
    setSelectedCompany: onSetSelectedCompany,
    isAdmin,
  };

  return <AuthContext.Provider value={value}>{!loading && children}</AuthContext.Provider>;
};

export function useAuth() {
  return useContext<AuthContextData>(AuthContext);
}
