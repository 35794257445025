import React, { createContext, useState } from "react";
import DocViewer from "../components/DocViewer";
import StorageBucket from "../models/StorageBucket";
import { downloadFile, getFileExtensionFromName } from "../util/files";

interface ViewerDetails {
  fileName: string;
  pathSegments: string[];
  companyId?: string;
  source: StorageBucket;
}

interface DocumentViewerContextValue {
  viewFile: (fileName: string, pathSegments: string[], companyId: string, source: StorageBucket) => void;
}

export const DocumentViewerContext = createContext<DocumentViewerContextValue | null>(null);

export const DocumentViewerContextProvider: React.FC = (props) => {
  const [documentDetails, setDocumentDetails] = useState<ViewerDetails | null>(null);
  const [open, setOpen] = useState<boolean>(false);

  function viewFile(fileName: string, pathSegments: string[], companyId: string, source: StorageBucket) {
    if (source === StorageBucket.UserContent) {
      if (pathSegments.length > 0 && pathSegments[0] !== companyId) {
        pathSegments.splice(0,0,companyId);
      }
    }
    const fileExtension = getFileExtensionFromName(fileName);
    switch (fileExtension) {
      case "pdf":
        downloadFile(true, fileName, pathSegments, source, companyId);
        break;
      case "doc":
        setDocumentDetails({ fileName, pathSegments, companyId, source });
        setOpen(true);
        break;
      case "docx":
        setDocumentDetails({ fileName, pathSegments, companyId, source });
        setOpen(true);
        break;
      case "xls":
        setDocumentDetails({ fileName, pathSegments, companyId, source });
        setOpen(true);
        break;
      case "xlsx":
        setDocumentDetails({ fileName, pathSegments, companyId, source });
        setOpen(true);
        break;
      default:
        downloadFile(true, fileName, pathSegments, source, companyId);
        break;
    } 
  }

  return (
    <DocumentViewerContext.Provider value={{ viewFile }}>
      { open && documentDetails && <DocViewer open={open} setOpen={setOpen} fileName={documentDetails.fileName} path={documentDetails.pathSegments} source={documentDetails.source} selectedCompanyId={documentDetails.companyId}></DocViewer>}
      {props.children}
    </DocumentViewerContext.Provider>
  );
};
