import { Paper } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import FolderIcon from '@mui/icons-material/Folder';
import { posix } from '../util/path';
import { FileObject } from '@supabase/storage-js';

interface DirTileProps {
    dirModel: FileObject,
    path: string
}


const DirTile: React.FC<DirTileProps> = ({dirModel, path})=>{
    const linkUrl = posix.join(['/docs', path, dirModel.name]);
    return <Link to={linkUrl}><Paper sx={{
            p: 2,
            m: 2,
            pt: 1,
            cursor: "pointer",
            height: "200px",
            '&:hover': {
                background: '#f8f8f8',
                transition: 'background 0.5s'
            }
        }}>
            <h3>
                <FolderIcon></FolderIcon>{dirModel.name}
            </h3>
        </Paper>
    </Link>
}

export default DirTile;