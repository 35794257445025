import { Checkbox, Divider, FormControlLabel } from "@mui/material";
import { RequiredDocumentWithLinkedDocument } from "../../data-access/required-documents";
import { useAuth } from "../../contexts/auth";
import { useContext } from "react";
import { DocumentViewerContext } from "../../contexts/DocumentViewerContext";
import StorageBucket from "../../models/StorageBucket";

type Props = {
  item: RequiredDocumentWithLinkedDocument;
};

const RequiredDocumentWithLink = ({ item }: Props) => {
  const authContext = useAuth();
  const docViewerContext = useContext(DocumentViewerContext);

  function onLinkedDocumentClicked(path?: string) {
    if (path == null || path === "") {
      console.error("Path for document is empty");
      return;
    }

    if (authContext?.selectedCompany == null) {
      console.error("Missing auth context");
      return;
    }

    const pathTokens = path.split("/");

    const fileName = pathTokens.length > 0 ? pathTokens[pathTokens.length - 1] : "";
    const pathTokensWithoutFilename = pathTokens.slice(0, -1);

    docViewerContext?.viewFile(
      fileName,
      pathTokensWithoutFilename,
      authContext.selectedCompany.id,
      StorageBucket.UserContent
    );
  }

  return (
    <>
      <FormControlLabel
        label={item.requiredDocument.name}
        sx={{ cursor: "pointer" }}
        onClick={() => onLinkedDocumentClicked(item?.companyRequiredDocument?.path)}
        control={
          <Checkbox
            checked={item.companyRequiredDocument != null}
            style={{ pointerEvents: "none", color: "#090" }}
          ></Checkbox>
        }
      ></FormControlLabel>
      <Divider />
    </>
  );
};

export default RequiredDocumentWithLink;
