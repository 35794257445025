import { Button, TableCell } from "@mui/material";
import { FileObjectWithSource } from "../../../../data-access/directory";
import CompanyUser from "../../types/CompanyUser";
import StoragePermissionModel from "../../../../models/StoragePermissionModel";
import { useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import { StoragePermission } from "./types";
import EditRow from "./EditRow";
import { useAuth } from "../../../../contexts/auth";
import { deletePermissionForUser, insertPermissionForUser } from "../../data-access/permissions";
import { useToast } from "../../../../contexts/toastContext";
import CompanyModel from "../../../../models/CompanyModel";

type Props = {
  user: CompanyUser;
  company: CompanyModel;
  directories?: FileObjectWithSource[];
  permissions?: StoragePermissionModel[];
};
const PermissionsForUser = ({ user, company, directories, permissions }: Props) => {
  const [editMode, setEditMode] = useState(false);
  const authContext = useAuth();
  const toast = useToast();

  if (directories == null) return null;

  async function onSaveChanges(newPermissions: StoragePermissionModel[]) {
    if (permissions != null) {
      removeRevokedPermissions(permissions, newPermissions);
      addNewPermissions(permissions, newPermissions);
    }
    const updatedPermissions = setEditMode(false);
  }

  async function removeRevokedPermissions(
    oldPermissions: StoragePermissionModel[],
    newPermissions: StoragePermissionModel[]
  ) {
    const noAccessPermissions = newPermissions.filter(
      (x) => x.read_permission == false && x.write_permission == false
    );

    for (const permission of noAccessPermissions) {
      try {
        await deletePermissionForUser(permission);
        toast?.success(`Removed`);
      } catch (e) {
        alert("Something went wrong");
      }
    }
  }

  async function addNewPermissions(
    oldPermissions: StoragePermissionModel[],
    newPermissions: StoragePermissionModel[]
  ) {
    const permissionsToAdd = newPermissions.filter((x) => {
      const matchingOldPermission = oldPermissions.find((y) => y.id === x.id);
      return matchingOldPermission == null;
    });

    for (const permission of permissionsToAdd) {
      try {
        await insertPermissionForUser(permission);
        toast?.success("Added");
      } catch (e) {
        alert("Something went wrong");
      }
    }
  }

  if (editMode)
    return (
      <EditRow
        userId={user.id}
        companyId={company.id!}
        directories={directories}
        permissions={permissions}
        cancelEditMode={() => setEditMode(false)}
        saveChanges={onSaveChanges}
      ></EditRow>
    );

  return (
    <DisplayRow
      directories={directories}
      permissions={permissions}
      setEditMode={() => setEditMode(true)}
    ></DisplayRow>
  );
};

type DisplayRowProps = {
  directories: FileObjectWithSource[];
  permissions?: StoragePermissionModel[];
  setEditMode: () => void;
};

const DisplayRow = ({ directories, permissions, setEditMode }: DisplayRowProps) => {
  function getPermissionLabel(permissionModel?: StoragePermissionModel) {
    if (permissionModel == null) return "none";

    if (permissionModel.write_permission) return "read/write";

    if (permissionModel.read_permission) return "read";

    return "none";
  }

  return (
    <>
      {directories.map((dir) => {
        const dirPermission = permissions?.find((p) => p.path === dir.name);
        const label = getPermissionLabel(dirPermission);
        let storagePermission: StoragePermission = "none";
        if (dirPermission?.write_permission) {
          storagePermission = "read-write";
        } else if (dirPermission?.read_permission) {
          storagePermission = "read";
        }

        return <TableCell key={dir.name}>{label}</TableCell>;
      })}
      <TableCell>
        <Button onClick={() => setEditMode()}>
          <EditIcon></EditIcon>
        </Button>
      </TableCell>
    </>
  );
};

export default PermissionsForUser;
