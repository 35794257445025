import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

const FileMenu: React.FC<{
  handleDownload: () => void;
  handleRecycle: () => void;
  handleCopyLink: () => void;
  handleRename: () => void;
}> = ({ handleDownload, handleRecycle, handleCopyLink, handleRename }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  async function onDownload() {
    await handleDownload();
    handleClose();
  }

  async function onRecycle() {
    await handleRecycle();
    handleClose();
  }

  async function onCopyLink() {
    await handleCopyLink();
    handleClose();
  }

  async function onRename() {
    await handleRename();
    handleClose();
  }

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        id="menu-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <i className={"fa fa-bars"}></i>
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem onClick={onDownload}>Download</MenuItem>
        <MenuItem onClick={onRecycle}>Delete</MenuItem>
        <MenuItem onClick={onCopyLink}>Copy Link</MenuItem>
        <MenuItem onClick={onRename}>Rename</MenuItem>
      </Menu>
    </div>
  );
};

export default FileMenu;
