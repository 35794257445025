import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { supabaseClient } from "../util/supabase";

const SearchResults: React.FC = () => {
  let [searchParams, setSearchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState<any[] | null>(null);

  useEffect(() => {
    async function loadResults() {
      const searchPattern = searchParams.get("query");
      if (!searchPattern) {
        return;
      }
      setLoading(true);
      try {
        const { data, error } = await supabaseClient
          .from("storage_objects")
          .select()
          .ilike("name", `%${searchPattern}%`);

        if (!error) {
          setResults(data);
        } else {
          console.error(error);
        }
      } catch (e: any) {
        console.error(e);
        alert("Something went wrong");
      } finally {
        setLoading(false);
      }
    }

    loadResults();
  }, [searchParams]);

  return (
    <>
      {loading && "Loading..."}
      {!loading && results && (
        <table>
          <tbody>
            {results.map((x, i) => (
              <tr key={i}>
                <td>{x.name}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </>
  );
};

export default SearchResults;
