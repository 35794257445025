import React from "react";
import AddIcon from "@mui/icons-material/Add";
import { Button, Menu, MenuItem, styled, Tooltip } from "@mui/material";
import { supabaseClient } from "../util/supabase";

export interface MenuItemDefinition {
  label: string;
  onClick: () => void;
}

const StyledMenuButton = styled(Button)`
  border-radius: 0px 4px 4px 0px;
  min-width: 30px;
  left: -5px;
`;

const ButtonMenu: React.FC<{
  buttonContent: React.ReactNode;
  menuItems: MenuItemDefinition[];
  tooltip: string;
  standalone?: boolean;
}> = ({ buttonContent, menuItems, tooltip, standalone = true }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    event.preventDefault();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Tooltip title={tooltip}>
        {standalone ? (
          <Button onClick={handleClick} variant="outlined">
            {buttonContent}
          </Button>
        ) : (
          <StyledMenuButton onClick={handleClick} variant="outlined">
            {buttonContent}
          </StyledMenuButton>
        )}
      </Tooltip>
      <Menu id="core-upload-menu" anchorEl={anchorEl} open={open} onClose={handleClose}>
        {menuItems.map((menuItem, i) => (
          <MenuItem
            key={i}
            onClick={(event: React.MouseEvent<HTMLLIElement>) => {
              event.preventDefault();
              menuItem.onClick();
              handleClose();
            }}
          >
            {menuItem.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default ButtonMenu;
