import { Button, TextField } from "@mui/material";
import { useState } from "react";
import { useToast } from "../../contexts/toastContext";
import { supabaseClient } from "../../util/supabase";

type Props = {
  onComplete: (email: string) => void;
};

const SendOtp = ({ onComplete }: Props) => {
  const [email, setEmail] = useState("");
  const toast = useToast();

  async function onSubmit() {
    const { data, error } = await supabaseClient.auth.signInWithOtp({
      email: email,
      options: {
        shouldCreateUser: false,
      },
    });

    if (error != null) {
      toast.error("Something went wrong");
      console.error(error);
      return;
    }

    onComplete(email);
    toast.success("Check your email");
  }

  return (
    <>
      <h4>Enter your email address so we can send you a one time access code</h4>
      <TextField
        placeholder="Email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      ></TextField>
      <Button variant="contained" onClick={onSubmit}>
        Submit
      </Button>
    </>
  );
};

export default SendOtp;
