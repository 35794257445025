import React from "react";
import { useAuth } from "../contexts/auth";

const AdminOnly: React.FC = ({ children }) => {
  const auth = useAuth();

  if (!auth.isAdmin) return null;

  return <>{children}</>;
};

export default AdminOnly;
