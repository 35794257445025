import React, { useContext, useEffect, useState } from "react";
import { Button, Grid, Stack, styled } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { FileObjectWithSource, getTopLevelDirectories } from "../data-access/directory";
import FileIconDisplay from "./FileIconDisplay";
import { posix } from "../util/path";
import ButtonMenu, { MenuItemDefinition } from "./ButtonMenu";
import MenuIcon from "@mui/icons-material/Menu";
import { downloadFile, getTrimmedBaseUrl, sortFilesByVersionNumbers } from "../util/files";
import StorageBucket from "../models/StorageBucket";
import RecycleFilesModal from "./RecycleFilesModal";
import DocViewer from "./DocViewer";
import { useAuth } from "../contexts/auth";
import RenameFileModal from "./RenameFileModal";
import { ToastContext, useToast } from "../contexts/toastContext";
import { DocumentViewerContext } from "../contexts/DocumentViewerContext";
import AdminOnly from "./AdminOnly";

const officeFileTypes = [".doc", ".docx", ".ppt", ".pptx", ".xls", ".xlsx"];

export const StyledCategoryTile = styled(Button)`
  position: relative;
  width: 150px;
  height: 150px;
  margin-left: 5px;
`;

export const StyledTemplateTile = styled(Button)`
  position: relative;
  width: 400px;
  height: 150px;
  margin-left: 5px;
  & i {
    font-size: 48px;
  }
`;

export const StyledGrid = styled(Grid)`
  padding: 30px;
  & .MuiButton-root {
    text-transform: none;
  }
`;

export const CategoryTile: React.FC<{
  dir: FileObjectWithSource;
  baseUrl: string;
  reload: () => void;
}> = ({ dir, baseUrl, reload }) => {
  const toast = useToast();

  const documentViewerContext = useContext(DocumentViewerContext);

  const url = posix.join([baseUrl, dir.name]);

  const auth = useAuth();
  const navigate = useNavigate();

  const [recycling, setRecycling] = useState(false);
  const [renaming, setRenaming] = useState(false);
  const [docViewerOpen, setDocViewerOpen] = useState(false);

  const isFolder = dir.id == null;

  async function onDownloadFile() {
    const trimmedPath = getTrimmedBaseUrl(baseUrl.split("/"));
    await downloadFile(true, dir.name, trimmedPath, dir.source, auth.selectedCompany?.id);
  }

  async function onCopyLink() {
    await window.navigator.clipboard.writeText(url);
    toast?.success("Copied");
  }

  const menuItems: MenuItemDefinition[] = [
    {
      label: "Delete",
      onClick: () => setRecycling(true),
    },
    {
      label: "Rename",
      onClick: () => setRenaming(true),
    },
    {
      label: "Download",
      onClick: () => onDownloadFile(),
    },
    {
      label: "Copy Link",
      onClick: () => onCopyLink(),
    },
  ];

  function onClick() {
    if (isFolder) {
      //tile is a directory so navigate on click
      navigate(url);
    } else {
      //tile is a file so attempt to load it
      const extensionName = posix.extname(dir.name);
      const trimmedPath = getTrimmedBaseUrl(baseUrl.split("/"));
      if (officeFileTypes.includes(extensionName)) {
        documentViewerContext?.viewFile(
          dir.name,
          trimmedPath,
          auth?.selectedCompany?.id || "",
          dir.source
        );
      } else {
        downloadFile(true, dir.name, trimmedPath, dir.source, auth?.selectedCompany?.id);
      }
    }
  }

  function onRecycleComplete() {
    setRecycling(false);
    reload();
  }

  function onRenameComplete() {
    setRenaming(false);
    reload();
  }

  const trimmedPath = getTrimmedBaseUrl(baseUrl.split("/"));

  return (
    <>
      <Stack direction="row">
        {isFolder && (
          <StyledCategoryTile onClick={onClick} color={"primary"} variant={"contained"}>
            <Stack direction={"column"} justifyContent="center" alignItems="center">
              <FileIconDisplay file={dir}></FileIconDisplay>
              <span>{dir.name}</span>
            </Stack>
          </StyledCategoryTile>
        )}
        {!isFolder && (
          <StyledTemplateTile onClick={onClick} color={"primary"} variant={"contained"}>
            <Stack direction={"row"} alignItems={"center"} justifyContent={"center"} spacing={2}>
              <FileIconDisplay file={dir}></FileIconDisplay>
              <span>{dir.name}</span>
            </Stack>
          </StyledTemplateTile>
        )}
        <AdminOnly>
          <div style={{ height: "40px", width: "20px" }}>
            <ButtonMenu
              buttonContent={<MenuIcon sx={{}}></MenuIcon>}
              menuItems={menuItems}
              tooltip=""
              standalone={false}
            ></ButtonMenu>
          </div>
        </AdminOnly>
      </Stack>
      <DocViewer
        fileName={dir.name}
        path={trimmedPath}
        source={dir.source}
        selectedCompanyId={auth?.selectedCompany?.id}
        open={docViewerOpen}
        setOpen={setDocViewerOpen}
      ></DocViewer>
      {recycling && (
        <RecycleFilesModal
          fileObject={dir}
          baseUrl={trimmedPath}
          storageBucket={StorageBucket.CoreContent}
          open={recycling}
          handleCancel={() => setRecycling(false)}
          handleRecycleComplete={onRecycleComplete}
        ></RecycleFilesModal>
      )}
      {renaming && (
        <RenameFileModal
          fileObject={dir}
          baseUrl={trimmedPath}
          storageBucket={StorageBucket.CoreContent}
          open={renaming}
          handleCancel={() => setRenaming(false)}
          handleRenameComplete={onRenameComplete}
        ></RenameFileModal>
      )}
    </>
  );
};

const BusinessCategoriesList: React.FC = () => {
  const [directories, setDirectories] = useState<FileObjectWithSource[] | null>();

  async function loadData() {
    try {
      const topLevelDirectories = await getTopLevelDirectories();
      const dirsSorted = sortFilesByVersionNumbers(topLevelDirectories || []);
      setDirectories(dirsSorted);
    } catch (err) {
      console.error(err);
    }
  }

  useEffect(() => {
    loadData();
  }, []);

  return (
    <StyledGrid container direction={"row"} spacing={5} mb={5}>
      {directories &&
        directories.map((dir, i) => {
          return (
            <Grid item xs={4} key={i}>
              <CategoryTile dir={dir} baseUrl="/docs/" reload={loadData}></CategoryTile>
            </Grid>
          );
        })}
    </StyledGrid>
  );
};

export default BusinessCategoriesList;
