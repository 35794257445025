import { supabaseClient } from "../util/supabase";

export async function getCompanyFilesForPath(companyId: string, filePath: string) {
  const fullPath = `${companyId}/${filePath}`;

  const { data, error } = await supabaseClient.storage.from("user-content").list(fullPath);

  if (error != null) {
    throw Error(error?.message);
  }

  return data;
}
