import React, { useEffect, useState } from "react";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { Button, CircularProgress, Menu, MenuItem } from "@mui/material";
import { supabaseClient } from "../util/supabase";
import AdminOnly from "./AdminOnly";
import { useNavigate } from "react-router";
import { User } from "@supabase/supabase-js";
import { useToast } from "../contexts/toastContext";

const UserMenu: React.FC = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const toast = useToast();

  const [user, setUser] = useState<User | null>(null);

  useEffect(() => {
    async function loadUser() {
      const {
        data: { user },
        error,
      } = await supabaseClient.auth.getUser();
      if (error != null) {
        toast.error("Failed to load user");
        console.error(error);
        return;
      }
      setUser(user);
    }
    loadUser();
  }, []);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const navigate = useNavigate();

  async function logout() {
    await supabaseClient.auth.signOut();
  }

  function navigateAdminSettings() {
    navigate("admin-settings");
  }

  if (user == null) {
    return <CircularProgress></CircularProgress>;
  }

  return (
    <>
      <Button onClick={handleClick} sx={{ color: "white" }}>
        <AccountCircleIcon></AccountCircleIcon>
      </Button>
      <Menu id="user-menu" anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem>{user?.email}</MenuItem>
        <AdminOnly>
          <MenuItem onClick={navigateAdminSettings}>Admin Settings</MenuItem>
        </AdminOnly>
        <MenuItem onClick={logout}>Log Out</MenuItem>
      </Menu>
    </>
  );
};

export default UserMenu;
