import { Alert, Button, Card, Stack } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import { useAuth } from "../contexts/auth";
import { ToastContext, useToast } from "../contexts/toastContext";
import PasswordInput from "../forms/PasswordInput";
import { supabaseClient } from "../util/supabase";
import { User } from "@supabase/supabase-js";

interface Inputs {
  password: string;
  confirmPassword: string;
}

const UpdatePassword: React.FC<{ access_token: string }> = ({ access_token }) => {
  const auth = useAuth();
  const navigate = useNavigate();
  const [isUpdating, setIsUpdating] = useState(false);
  const [user, setUser] = useState<User | null>(null);
  const toast = useToast();

  const {
    register,
    getValues,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<Inputs>();

  useEffect(() => {
    async function loadUser() {
      const {
        data: { user },
        error,
      } = await supabaseClient.auth.getUser();
      if (error != null) {
        toast.error("Failed to load user");
        console.error(error);
        return;
      }
      setUser(user);
    }
    loadUser();
  }, []);

  const rules = {
    password: {
      required: "Password is required",
      validate: (value: string) =>
        value && value.length > 5 ? null : "Password must be at least 6 characters long",
    },
    confirmPassword: {
      required: "Re-enter your password",
      validate: (value: string) =>
        getValues("password") === value ? null : "Passwords must match",
    },
  };

  async function onSetPassword(formValues: Inputs) {
    setIsUpdating(true);
    const { error, data } = await supabaseClient.auth.updateUser({
      password: formValues.password,
      nonce: access_token,
    });

    setIsUpdating(false);

    if (error != null) {
      toast?.error("Sorry, something went wrong!");
      console.error(JSON.stringify(error));
    } else {
      toast?.success("Password updated!");
      setTimeout(() => {
        navigate("/");
      }, 1000);
    }
  }

  return (
    <Stack
      direction={"column"}
      sx={{ width: "100%", height: "100vh", alignItems: "center", justifyContent: "center" }}
    >
      <Card sx={{ width: "600px", padding: "40px" }}>
        <Stack direction="column" gap={2}>
          <img
            src="/img/BlueGoldLandscape.svg"
            width={"400px"}
            style={{ margin: "15px 0px 0px 0px" }}
          ></img>
          <h3>Reset your password</h3>
          <span>{user?.email}</span>
          <PasswordInput
            name="password"
            label="password"
            control={control}
            rules={rules.password}
          ></PasswordInput>
          {errors.password && <Alert severity="error">{errors.password?.message}</Alert>}
          <PasswordInput
            name="confirmPassword"
            label="confirm password"
            control={control}
            rules={rules.confirmPassword}
          ></PasswordInput>
          {errors.confirmPassword && (
            <Alert severity="error">{errors.confirmPassword?.message}</Alert>
          )}
          <Button onClick={handleSubmit(onSetPassword)} variant={"contained"} disabled={isUpdating}>
            Save
          </Button>
        </Stack>
      </Card>
    </Stack>
  );
};

export default UpdatePassword;
