import React, { useState, useEffect } from "react";
import Stack from "@mui/material/Stack";

import "remirror/styles/all.css";

import {
  getTextContentForPath,
  RichTextRepoType,
  TextContentRecord,
} from "../data-access/text-content";
import RichTextEdit from "./RichTextEdit";
import RichTextReadOnly from "./RichTextReadOnly";
import { Grid, Typography } from "@mui/material";

interface RichTextDisplayProps {
  path: string;
}
const RichTextDisplay: React.FC<RichTextDisplayProps> = ({ path }) => {
  const [editable, setEditable] = useState(false);
  const [textContent, setTextContent] = useState<TextContentRecord | undefined>(undefined);
  const [linksContent, setLinksContent] = useState<TextContentRecord | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    async function getData() {
      try {
        const textContentResult = await getTextContentForPath(path, RichTextRepoType.MainContent);
        setTextContent(textContentResult);
        const linksContentResult = await getTextContentForPath(path, RichTextRepoType.LinksContent);
        setLinksContent(linksContentResult);
      } catch (e: any) {
        console.error(e);
      } finally {
        setIsLoading(false);
      }
    }
    getData();
  }, [path]);

  const textContentUpdated = (newContent: TextContentRecord) => {
    setTextContent(newContent);
  };

  const linksContentUpdated = (newContent: TextContentRecord) => {
    setLinksContent(newContent);
  };

  return (
    <Stack className={"rich-text-display"}>
      <div>
        {!isLoading && !editable && (
            <Grid container>
              <Grid item xs={linksContent ? 8 : 12}>
                <RichTextReadOnly
                  textContent={textContent}
                  setEditable={setEditable}
                ></RichTextReadOnly>
              </Grid>
              {linksContent && (
                <Grid item xs={4} p={1}>
                  <Typography variant={"h6"}>Links</Typography>
                  <RichTextReadOnly
                    textContent={linksContent}
                    setEditable={setEditable}
                  ></RichTextReadOnly>
                </Grid>
              )}
            </Grid>
        )}
        {!isLoading && editable && (
          <Stack direction="column" gap={4}>
            <RichTextEdit
              name="Text Content"
              textContent={textContent}
              path={path}
              editable={editable}
              type={RichTextRepoType.MainContent}
              setEditable={setEditable}
              textContentUpdated={textContentUpdated}
            ></RichTextEdit>
            <RichTextEdit
              name="Links Content"
              textContent={linksContent}
              path={path}
              editable={editable}
              type={RichTextRepoType.LinksContent}
              setEditable={setEditable}
              textContentUpdated={linksContentUpdated}
            ></RichTextEdit>
          </Stack>
        )}
      </div>
    </Stack>
  );
};

export default RichTextDisplay;
