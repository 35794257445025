import { Button, Paper, Stack, TextField } from "@mui/material";
import { useState } from "react";
import { supabaseClient } from "../util/supabase";
import { useToast } from "../contexts/toastContext";
import { SignInWithPasswordlessCredentials } from "@supabase/supabase-js";
import SendOtp from "../features/forgot-password/SendOtp";
import EnterOtp from "../features/forgot-password/EnterOtp";
import ChooseNewPassword from "../features/forgot-password/ChooseNewPassword";

const ForgotPassword2 = () => {
  const toast = useToast();
  const [step, setStep] = useState(1);
  const [email, setEmail] = useState("");

  function onStep1Complete(userEmail: string) {
    setEmail(userEmail);
    setStep(2);
  }

  function onStep2Complete() {
    setStep(3);
  }

  return (
    <Paper sx={{ marginLeft: "auto", marginRight: "auto", width: "600px", padding: "30px" }}>
      <Stack gap={3}>
        <img
          src="/img/BlueGoldLandscape.svg"
          width={"400px"}
          style={{ margin: "15px 0px 0px 0px" }}
        ></img>
        {step == 1 && <SendOtp onComplete={onStep1Complete}></SendOtp>}
        {step == 2 && <EnterOtp onComplete={onStep2Complete} email={email}></EnterOtp>}
        {step == 3 && <ChooseNewPassword></ChooseNewPassword>}
      </Stack>
    </Paper>
  );
};

export default ForgotPassword2;
