import { useState } from "react";
import { FileObjectWithSource } from "../../../../data-access/directory";
import StoragePermissionModel from "../../../../models/StoragePermissionModel";
import { StoragePermission } from "./types";
import { Button, Stack, TableCell } from "@mui/material";
import PermissionDropdown from "./PermissionDropdown";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";

type EditRowProps = {
  userId: string;
  companyId: string;
  directories: FileObjectWithSource[];
  permissions?: StoragePermissionModel[];
  cancelEditMode: () => void;
  saveChanges: (permissions: StoragePermissionModel[]) => void;
};

const EditRow = ({
  userId,
  companyId,
  directories,
  permissions,
  cancelEditMode,
  saveChanges,
}: EditRowProps) => {
  const [permissionModels, setPermissionModels] = useState(permissions || []);

  function onPermissionChanged(directory: FileObjectWithSource, newValue: StoragePermission) {
    let newPermissionModels = [...permissionModels];
    let newModel: StoragePermissionModel | undefined = newPermissionModels.find(
      (p) => p.path === directory.name
    );
    if (newModel == null) {
      newModel = {
        user_id: userId,
        company_id: companyId,
        path: directory.name,
        read_permission: ["read", "read-write"].includes(newValue),
        write_permission: newValue === "read-write",
      };
      newPermissionModels.push(newModel);
    } else {
      if (newValue === "read-write") {
        newModel.write_permission = true;
        newModel.read_permission = true;
      } else if (newValue === "read") {
        newModel.read_permission = true;
        newModel.write_permission = false;
      } else {
        newModel.read_permission = false;
        newModel.write_permission = false;
      }
    }

    setPermissionModels(newPermissionModels);
  }

  return (
    <>
      {directories.map((dir) => {
        const dirPermission = permissionModels?.find((p) => p.path === dir.name);
        let storagePermission: StoragePermission = "none";

        if (dirPermission?.write_permission) {
          storagePermission = "read-write";
        } else if (dirPermission?.read_permission) {
          storagePermission = "read";
        }

        return (
          <TableCell>
            <PermissionDropdown
              value={storagePermission}
              onChange={(newValue) => onPermissionChanged(dir, newValue)}
            ></PermissionDropdown>
          </TableCell>
        );
      })}

      <TableCell>
        <Stack direction={"row"}>
          <Button onClick={() => saveChanges(permissionModels)} size={"small"}>
            <CheckIcon></CheckIcon>
          </Button>
          <Button onClick={() => cancelEditMode()} size={"small"}>
            <CloseIcon></CloseIcon>
          </Button>
        </Stack>
      </TableCell>
    </>
  );
};

export default EditRow;
