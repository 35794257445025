import { Box, Button, Card, LinearProgress, Snackbar } from "@mui/material";
import React, { useState } from "react";

interface FileUploadProgressProps {
  fileName: string;
  index: number;
  total: number;
}
const FileUploadProgress: React.FC<FileUploadProgressProps> = ({ fileName, index, total }) => {
  let percentage = 0;
  if (index + 1 && total) {
    percentage = ((index + 1) / total) * 100;
  }
  return (
    <Card
      sx={{
        width: "500px",
        margin: "20px",
        padding: "5px",
        position: "fixed",
        top: "50px",
        right: "40px",
        backgroundColor: "white",
        color: "black",
        zIndex: "999",
      }}
    >
      <span>Uploading: {fileName}</span>
      <LinearProgress variant="determinate" value={percentage}></LinearProgress>
    </Card>
  );
};

export default FileUploadProgress;
