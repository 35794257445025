import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../contexts/auth';

const AuthenticatedContent: React.FC = ({ children }) => {
    
    const auth = useAuth();

    const hashParams = new URLSearchParams(window.location.hash.replace('#', ''));

    const type = hashParams?.get("type");

    const loginRequired = auth?.user == null && type !== "recovery";

    if (loginRequired) {
        return <Navigate to={"/login"} />;
    }
    else {
        return <>{children}</>;
    }
};

export default AuthenticatedContent;