import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import { useState } from "react";
import { useMutation } from "react-query";
import { inviteUserToCompany } from "../data-access/admin";
import CompanyModel from "../../../models/CompanyModel";
import { useToast } from "../../../contexts/toastContext";

type InviteUserButtonProps = {
  company: CompanyModel;
};

const InviteUserButton = ({ company }: InviteUserButtonProps) => {
  const [open, setOpen] = useState(false);
  const [email, setEmail] = useState<string>("");
  const [processing, setProcessing] = useState(false);
  const toast = useToast();

  const inviteUserMutation = useMutation(() => inviteUserToCompany(email, company.id), {
    onError(error, variables, context) {
      alert("Something went wrong");
      setProcessing(false);
    },
    onSuccess(data, variables, context) {
      toast.success("Success");
      setProcessing(false);
      setOpen(false);
    },
  });

  function emailIsValid() {
    const re = /^\S+@\S+\.\S+$/;
    return re.test(email);
  }

  async function onConfirm() {
    setProcessing(true);
    await inviteUserMutation.mutate();
  }

  return (
    <>
      <Dialog open={open} fullWidth={true} maxWidth="sm">
        <DialogTitle>
          Invite user to <b>{company.name}</b>
        </DialogTitle>
        <DialogContent>
          <TextField
            value={email}
            label={"email"}
            onChange={(e) => setEmail(e.target.value)}
            sx={{ width: "100%" }}
          ></TextField>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Cancel</Button>
          <Button
            variant={"contained"}
            disabled={!emailIsValid() || processing}
            onClick={onConfirm}
          >
            {processing && <CircularProgress />}
            Invite
          </Button>
        </DialogActions>
      </Dialog>
      <Button
        variant="contained"
        color="success"
        size="small"
        sx={{ height: "30px" }}
        onClick={() => setOpen(true)}
      >
        Invite User
      </Button>
    </>
  );
};

export default InviteUserButton;
