import { useEffect, useRef, useState } from "react";
import { Button, Stack } from "@mui/material";
import { getCompanyLogoUrlIfExists } from "../data-access/companyLogo";
import { useToast } from "../../../contexts/toastContext";
import { supabaseClient } from "../../../util/supabase";

type Props = {
  companyId: string;
  logoFilename?: string;
};

const CompanyLogoUpload = ({ companyId, logoFilename }: Props) => {
  const [logoUrl, setLogoUrl] = useState<string | null>("");
  const uploadFilesRef = useRef<HTMLInputElement>(null);

  const toast = useToast();

  useEffect(() => {
    async function assignLogoUrl() {
      if (logoFilename == null) return;

      const logoUrlResult = await getCompanyLogoUrlIfExists(logoFilename);
      console.log(logoUrlResult);
      if (logoUrlResult != null) {
        setLogoUrl(logoUrlResult);
      }
    }
    assignLogoUrl();
  }, []);

  async function onLogoUpload() {
    // @ts-ignore
    const files = document.getElementById("upload-logo").files;
    if (files == null || files.length === 0) {
      console.error("No array of files found", files);
      toast?.error("Something went wrong, we couldn't upload your files");
      return;
    }

    const file = files[0] as File;

    const fullFilePath = `${companyId}/${file.name}`;

    try {
      const { data: uploadData, error: uploadError } = await supabaseClient.storage
        .from("company-data")
        .upload(fullFilePath, file, {
          cacheControl: "3600",
          upsert: false,
        });

      if (uploadError != null) {
        console.error(uploadError);
        toast?.error("Something went wrong");
        return;
      }

      const { data: dbData, error: dbError } = await supabaseClient
        .from("company")
        .update({ logo_path: fullFilePath })
        .match({ id: companyId });

      if (dbError != null) {
        console.error(dbError);
        toast?.error("Something went wrong");
        return;
      }

      toast?.success("Updated successfully");
    } catch (e) {
      toast?.error("Something went wrong");
    }
  }

  return (
    <Stack direction="row" gap={2}>
      {logoUrl && <img src={logoUrl} style={{ width: "150px" }}></img>}
      <input
        type="file"
        id={"upload-logo"}
        ref={uploadFilesRef}
        onChange={onLogoUpload}
        style={{ display: "none" }}
      ></input>
      <Button onClick={(e) => uploadFilesRef.current?.click()}>Upload logo</Button>
    </Stack>
  );
};

export default CompanyLogoUpload;
