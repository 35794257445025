import React from "react";
import { useMatch } from "react-router";
import { DocsDisplay } from "../components/DocsDisplay";
import { DisplayMode } from "../models/DisplayMode";
import { useAuth } from "../contexts/auth";

const DocsPage: React.FC = () => {
  const match = useMatch("/docs/*");
  const auth = useAuth();

  const filePath = `/${match?.params["*"]}`;
  return (
    <>
      {auth?.selectedCompany?.id && (
        <DocsDisplay url={filePath} defaultDisplayMode={DisplayMode.Table}></DocsDisplay>
      )}
    </>
  );
};

export default DocsPage;
