import CompanyModel from "../models/CompanyModel";
import { supabaseClient } from "../util/supabase";

export async function updateCompanyDetails(company: CompanyModel) {
  const { data, error } = await supabaseClient
    .from("company")
    .update({ trading_name: company.trading_name })
    .match({ id: company.id });

  if (error != null) {
    console.error(error);
    throw Error("Something went wrong");
  }
}
