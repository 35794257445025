import { supabaseClient } from "../../../util/supabase";

export async function getBlogData() {
  const { data, error } = await supabaseClient.functions.invoke("get-blog", {});

  if (error != null) {
    console.error(error);
    throw Error(error.message);
  }

  return data;
}
