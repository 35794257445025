import UserFavouriteModel from "../models/UserFavouriteModel";
import { supabaseClient } from "../util/supabase";

export async function getUserFavourites() {
  const { data, error } = await supabaseClient.from("user_favourite").select();

  if (error != null) {
    console.error(error);
    throw Error("Something went wrong");
  }

  return data as UserFavouriteModel[];
}

export async function addFavourite(name: string, url: string) {
  const {
    data: { user },
    error: userError,
  } = await supabaseClient.auth.getUser();

  if (user == null) {
    throw Error("No user");
  }

  const { error } = await supabaseClient
    .from("user_favourite")
    .insert({ user_id: user.id, link_label: name, link_url: url });

  if (error != null) {
    console.error(error);
    throw Error("Something went wrong");
  }

  return;
}

export async function deleteFavourite(favourite: UserFavouriteModel) {
  const { data, error } = await supabaseClient
    .from("user_favourite")
    .delete()
    .eq("id", favourite.id);

  if (error != null) {
    console.error(error);
    throw Error("Something went wrong");
  }

  return data;
}
