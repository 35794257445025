import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import React, { useEffect, useState } from "react";
import StorageBucket from "../../models/StorageBucket";
import RecycleBinItems, { RecycleBinItemViewModel } from "./RecycleBinItems";
import AutoDeleteIcon from "@mui/icons-material/AutoDelete";
import { permanentlyDeleteFile, restoreFile } from "../../util/files";
import { useToast } from "../../contexts/toastContext";
import DeleteIcon from "@mui/icons-material/Delete";
import { getRecycleBinItems } from "../../data-access/directory";
import { recyclePathSegment } from "../../constants";

const RecycleBinModal: React.FC<{
  storageBucket: StorageBucket;
  companyId: string;
}> = ({ storageBucket, companyId }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>();
  const [showRecycle, setShowRecycle] = useState(false);
  const [allRecycleBinItems, setAllRecycleBinItems] = useState<RecycleBinItemViewModel[]>([]);
  const [selectedRecycleBinItems, setSelectedRecycleBinItems] = useState<string[]>([]);

  const toast = useToast();

  async function onPermanentlyDeleteItems() {
    for (const item of selectedRecycleBinItems) {
      const file = allRecycleBinItems.find((x) => x.fullPath === item);
      try {
        await permanentlyDeleteFile(item, storageBucket);
        toast?.success(`${file?.displayPath} deleted`);
      } catch (err) {
        console.error(err);
        toast?.error(`Failed to delete ${file?.displayPath}`);
      }
    }
  }

  async function onRestoreRecycledItems() {
    for (const item of selectedRecycleBinItems) {
      try {
        const file = allRecycleBinItems.find((x) => x.fullPath === item);
        if (file == null) continue;
        await restoreFile(item, storageBucket);
        toast?.success(`${file.displayPath} restored`);
      } catch (err) {
        console.error(err);
        toast?.error(`Failed to restore file`);
      }
    }
  }

  const oneOrMoreItemsSelected = allRecycleBinItems.length > 0;

  useEffect(() => {
    async function getItemsAsync() {
      try {
        setLoading(true);
        const items = await getRecycleBinItems(storageBucket, companyId);
        const itemViewModels = items.map((path) => {
          return {
            fullPath: path,
            displayPath: path.substring(recyclePathSegment.length + 1),
            selected: false,
          };
        });
        setAllRecycleBinItems(itemViewModels);
      } catch (err) {
        console.error(err);
        setError("Something went wrong");
      } finally {
        setLoading(false);
      }
    }
    //getItemsAsync(); TODO: this was firing way too many requests in production for some reason
  }, [storageBucket, companyId]);

  return (
    <>
      <Button onClick={() => setShowRecycle(true)}>
        <AutoDeleteIcon></AutoDeleteIcon> Recycled Items
      </Button>
      <Dialog open={showRecycle} fullWidth maxWidth={"md"}>
        <DialogTitle>Recycle Bin</DialogTitle>
        <DialogContent>
          <RecycleBinItems
            storageBucket={storageBucket}
            companyId={companyId}
            allRecycleBinItems={allRecycleBinItems}
            selectedRecycleBinItems={selectedRecycleBinItems}
            setSelectedRecycleBinItems={setSelectedRecycleBinItems}
          ></RecycleBinItems>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={onPermanentlyDeleteItems}
            variant={"contained"}
            color={"error"}
            disabled={!oneOrMoreItemsSelected}
          >
            <DeleteIcon></DeleteIcon> Permanently delete
          </Button>
          <Button
            onClick={onRestoreRecycledItems}
            variant={"outlined"}
            disabled={!oneOrMoreItemsSelected}
          >
            Restore selected files
          </Button>
          <Button onClick={() => setShowRecycle(false)}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default RecycleBinModal;
