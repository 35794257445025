import { Paper, Stack, TableContainer, TextField, Typography } from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import { FileObjectWithSource } from "../data-access/directory";
import FileUpload from "./FileUpload";
import StorageBucket from "../models/StorageBucket";
import { useAuth } from "../contexts/auth";
import RecycleBinModal from "./recycle-bin/RecycleBinModal";
import ReactDataGrid from "@inovua/reactdatagrid-community";
import "@inovua/reactdatagrid-community/index.css";
import FileIconDisplay from "./FileIconDisplay";
import { TypeColumn } from "@inovua/reactdatagrid-community/types";
import { posix } from "../util/path";
import { downloadFile, isFile } from "../util/files";
import { Link } from "react-router-dom";
import { DocumentViewerContext } from "../contexts/DocumentViewerContext";
import FileMenu from "./FileMenu";
import { ToastContext, useToast } from "../contexts/toastContext";
import RecycleFilesModal from "./RecycleFilesModal";
import RenameFileModal from "./RenameFileModal";

type FileContextMenuProps = {
  dir: FileObjectWithSource;
  path: string[];
  selectedCompanyId: string | undefined;
  reload: () => void;
};

const FileContextMenu = ({ dir, path, selectedCompanyId, reload }: FileContextMenuProps) => {
  const linkUrl = posix.join(["/docs", ...path, dir.name]);
  const toast = useToast();
  const [deleting, setDeleting] = useState(false);
  const [renaming, setRenaming] = useState(false);

  async function onCopyLink() {
    await window.navigator.clipboard.writeText(linkUrl);
    toast?.success("Copied");
  }

  function onRecycleComplete() {
    setDeleting(false);
    reload();
  }

  function onRenameComplete() {
    setRenaming(false);
    reload();
  }

  return (
    <>
      <FileMenu
        handleDownload={() => downloadFile(true, dir.name, path, dir.source, selectedCompanyId)}
        handleCopyLink={onCopyLink}
        handleRecycle={() => setDeleting(true)}
        handleRename={() => setRenaming(true)}
      ></FileMenu>
      {deleting && (
        <RecycleFilesModal
          fileObject={dir}
          baseUrl={path}
          storageBucket={StorageBucket.UserContent}
          companyId={selectedCompanyId}
          open={deleting}
          handleRecycleComplete={onRecycleComplete}
          handleCancel={() => setDeleting(false)}
        ></RecycleFilesModal>
      )}
      {renaming && (
        <RenameFileModal
          fileObject={dir}
          baseUrl={path}
          storageBucket={StorageBucket.UserContent}
          companyId={selectedCompanyId}
          open={renaming}
          handleCancel={() => setRenaming(false)}
          handleRenameComplete={onRenameComplete}
        ></RenameFileModal>
      )}
    </>
  );
};

type NameDisplayProps = {
  dir: FileObjectWithSource;
  path: string[];
  onFileClick: (dir: FileObjectWithSource) => void;
};

const NameDisplay: React.FC<NameDisplayProps> = ({ dir, path, onFileClick }) => {
  const isFileObj = isFile(dir);
  const linkUrl = posix.join(["/docs", ...path, dir.name]);

  if (isFileObj) {
    return (
      <a href="#" onClick={() => onFileClick(dir)}>
        {dir.name}
      </a>
    );
  } else {
    return <Link to={linkUrl}>{dir.name}</Link>;
  }
};

interface DisplayTableProps {
  dirs: FileObjectWithSource[];
  path: string[];
  reloadData: () => void;
  isLeafNode: boolean;
  readPermission: boolean;
  writePermission: boolean;
}
const DisplayTable: React.FC<DisplayTableProps> = ({
  dirs,
  path,
  reloadData,
  isLeafNode,
  readPermission,
  writePermission,
}) => {
  const auth = useAuth();
  const documentViewerContext = useContext(DocumentViewerContext);

  const selectedCompanyId = auth.selectedCompany?.id;

  const [searchText, setSearchText] = useState("");
  const [gridDataSource, setGridDataSource] = useState(dirs);
  const [gridRef, setGridRef] = useState<any>(null);
  const [selected, setSelected] = useState<any>(null);

  const searchTextRef = useRef(searchText);

  useEffect(() => {
    setSearchText("");
    setGridDataSource(dirs);
  }, [dirs]);

  searchTextRef.current = searchText;

  const gridStyle = { minHeight: 550 };

  // const renderName = ({ value }: { value: any}) => {

  // // const linkUrl = posix.join(["/docs", ...path, dir.name]);

  //   const lowerSearchText = searchTextRef.current.toLowerCase();
  //   if (!lowerSearchText) {
  //     return value;
  //   }

  //   const str = value + ""; // get string value
  //   const v = str.toLowerCase(); // our search is case insensitive
  //   const index = v.indexOf(lowerSearchText);

  //   if (index === -1) {
  //     return value;
  //   }
  //   return <Link url={#}>
  //     <span key="before">{str.slice(0, index)}</span>,
  //     <span key="match" style={{ background: "yellow", fontWeight: "bold" }}>
  //       {str.slice(index, index + lowerSearchText.length)}
  //     </span>,
  //     <span key="after">{str.slice(index + lowerSearchText.length)}</span>,
  //     </Link>;
  // }, []);

  function onFileClick(dir: FileObjectWithSource) {
    if (selectedCompanyId == null) {
      console.error("No company selected");
      return;
    }
    documentViewerContext?.viewFile(dir.name, path, selectedCompanyId, dir.source);
  }

  function onSearchChange(newValue: string) {
    if (gridRef == null) {
      console.error("No grid ref");
      return;
    }
    const visibleColumns = gridRef.current.visibleColumns as TypeColumn[];

    setSearchText(newValue);

    const newDataSource = dirs.filter((dir) => {
      for (const col of visibleColumns) {
        if (col?.id == null) {
          continue;
        }
        type ObjectKey = keyof FileObjectWithSource; // 🐉
        if (dir[col.id as ObjectKey] != null) {
          // 🐉
          if ((dir[col.id as ObjectKey] as string).toLowerCase().includes(newValue.toLowerCase())) {
            return true;
          }
        }
      }
      return false;
      // return visibleColumns.reduce((acc: any, col: TypeColumn) => {
      //   const v = (dir[col.id || 0] + "").toLowerCase(); // get string value
      //   return acc || v.indexOf(value.toLowerCase()) != -1; // make the search case insensitive
      // }, false);
    });

    setGridDataSource(newDataSource);
  }

  function renderName(value: { data: FileObjectWithSource }) {
    return <NameDisplay dir={value.data} path={path} onFileClick={onFileClick}></NameDisplay>;
  }

  const defaultColumns: TypeColumn[] = [
    {
      id: "icon",
      width: 30,
      render: ({ data }: any) => <FileIconDisplay file={data}></FileIconDisplay>,
    },
    {
      name: "name",
      header: "Name",
      render: renderName,
      defaultFlex: 1,
    },
    {
      id: "size",
      header: "Size",
      render: (value: { data: FileObjectWithSource }) => {
        let size = "";
        if (value.data?.metadata && (value.data?.metadata as any).size) {
          const sizeBytes = parseInt((value.data.metadata as any).size);
          if (sizeBytes !== null && sizeBytes !== 0) {
            size = (sizeBytes / 1024).toFixed(0).toString() + " KB";
          }
        }
        return <span>{size}</span>;
      },
    },
    {
      id: "menu",
      header: "",
      render: (value: { data: FileObjectWithSource }) => {
        return (
          <FileContextMenu
            dir={value.data}
            path={path}
            selectedCompanyId={selectedCompanyId}
            reload={reloadData}
          ></FileContextMenu>
        );
      },
    },
  ];

  return (
      <TableContainer component={Paper} sx={{ padding: "20px" }}>
        <Stack direction={"row"} sx={{ width: "100%" }} justifyContent={"space-between"}>
          <Typography variant={"h6"}>Files</Typography>
          <Stack direction={"row"}>
            <TextField
              placeholder={"search"}
              value={searchText}
              onChange={(e) => onSearchChange(e.target.value)}
            ></TextField>
            {selectedCompanyId && writePermission && (
              <RecycleBinModal
                storageBucket={StorageBucket.UserContent}
                companyId={selectedCompanyId}
              ></RecycleBinModal>
            )}
            {isLeafNode && writePermission && (
              <FileUpload
                storageBucket={StorageBucket.UserContent}
                currentPath={path}
                reloadData={reloadData}
              ></FileUpload>
            )}
          </Stack>
        </Stack>
        {dirs && (
          <div style={{ marginTop: "15px" }}>
            <ReactDataGrid
              showColumnMenuTool={false}
              onReady={setGridRef}
              idProperty={"id"}
              columns={defaultColumns}
              dataSource={gridDataSource}
              style={gridStyle}
              enableSelection={false}
              // onSelectionChange={onSelectionChange}
              onRowClick={() => null}
              showCellBorders={false}
              showZebraRows={false}
            ></ReactDataGrid>
          </div>
        )}
      </TableContainer>
  );
};

export default DisplayTable;
