import { PostgrestResponse } from "@supabase/supabase-js";
import { supabaseClient } from "../util/supabase";

export interface SearchResultItem {
  id: string;
  bucket_id: string;
  name: string;
  owner: string;
  created_at: string;
  updated_at: string;
  last_accessed_at: string;
  metadata: any;
  path_tokens: string[];
}

export async function searchByNameContains(
  searchString: string
): Promise<PostgrestResponse<SearchResultItem>> {
  return await supabaseClient.from("storage_objects").select().ilike("name", `%${searchString}%`);
}
