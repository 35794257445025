import React, { useCallback, useContext, useState } from "react";
import { useAuth } from "../../contexts/auth";
import { searchByNameContains, SearchResultItem } from "../../data-access/search";
import debounce from "lodash/debounce";
import { Box, styled, TextField } from "@mui/material";
import SearchResults from "./SearchResults";
import {
  FileObjectWithSource,
  fileObjectWithSourceFromSearchResultItem,
} from "../../data-access/directory";
import { DocumentViewerContext } from "../../contexts/DocumentViewerContext";
import { useNavigate } from "react-router";

const StyledTextField = styled(TextField)`
  margin: 5px;
  .MuiInputBase-root {
    background-color: white;
  }
`;

const SearchInput: React.FC = () => {
  const [searchString, setSearchString] = useState<string>("");
  const [searchResults, setSearchResults] = useState<SearchResultItem[]>([]);
  const [searchResultsOpen, setSearchResultsOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState<FileObjectWithSource | null>();
  const authContext = useAuth();
  const docViewerContext = useContext(DocumentViewerContext);

  const navigate = useNavigate();

  let companyIds: string[] = [];
  if (authContext?.companies != null) {
    companyIds = authContext?.companies.map((x) => x.id) || [];
  }

  const debouncedSearch = useCallback(debounce(runSearchQuery, 500), []);

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    const searchString = e.currentTarget.value;
    setSearchString(searchString);
    if (searchString != null && searchString.length > 2) {
      debouncedSearch(e.currentTarget.value);
    }
  }

  async function runSearchQuery(searchString: string) {
    const { data, error } = await searchByNameContains(searchString);

    if (error != null) alert("Error");

    if (data != null) {
      if (data.length > 0) {
        setSearchResults(data);
        setSearchResultsOpen(true);
      }
    }
  }

  function onKeyUp(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.key === "Enter") {
      runSearchQuery(searchString);
    }
    if (e.key === "Escape") {
      setSearchString("");
      setSearchResults([]);
      setSearchResultsOpen(false);
    }
  }

  function searchResultItemIsCoreFile(item: SearchResultItem): boolean {
    return !companyIds.includes(item.path_tokens[0]);
  }

  let coreFiles: SearchResultItem[] = [];
  let yourFiles: SearchResultItem[] = [];

  if (searchResults != null) {
    coreFiles = searchResults.filter((x) => searchResultItemIsCoreFile(x));
    yourFiles = searchResults.filter((x) => !searchResultItemIsCoreFile(x));
  }

  function setOpen(newValue: boolean) {
    if (newValue === false) {
      setSelectedFile(null);
    }
  }

  function onSearchResultItemClicked(item: SearchResultItem) {

    if (authContext?.selectedCompany == null) {
      console.error("Missing auth context");
      return;
    }

    const itemAsFileObject = fileObjectWithSourceFromSearchResultItem(
      item,
      searchResultItemIsCoreFile(item)
    );

    const fileName = item.path_tokens.length > 0 ? item.path_tokens[item.path_tokens.length - 1] : "";
    const pathTokensWithoutFilename = item.path_tokens.slice(0,-1);
    
    docViewerContext?.viewFile( fileName, pathTokensWithoutFilename, authContext.selectedCompany.id, itemAsFileObject.source );
    // setSelectedFile(itemAsFileObject);
  }

  function onSearchResultFolderClicked(item: SearchResultItem) {
    const pathOnly = item.path_tokens.slice(0,-1);

    const newUrl = `/docs/${pathOnly.join("/")}`;
    setSearchResultsOpen(false);
    navigate(newUrl);
  }

  const path = selectedFile != null ? selectedFile.name.split("/")?.slice(0, -1).join("/") : "";
  const name = selectedFile != null ? selectedFile.name.split("/")?.at(-1) || "" : "";


  return (
    <>
      {searchResultsOpen && (
        <Box
          onClick={() => setSearchResultsOpen(false)}
          sx={{
            position: "absolute",
            width: "100%",
            height: "100vh",
            zIndex: 8888,
          }}
        ></Box>
      )}
      <Box sx={{ position: "relative", zIndex: 9999 }}>
        <StyledTextField
          placeholder="search"
          sx={{ width: "400px" }}
          value={searchString}
          onChange={handleChange}
          onKeyUp={onKeyUp}
        ></StyledTextField>
        {searchResultsOpen && (
          <SearchResults
            coreFiles={coreFiles}
            yourFiles={yourFiles}
            onSearchResultItemClicked={onSearchResultItemClicked}
            onSearchResultFolderClicked={onSearchResultFolderClicked}
          ></SearchResults>
        )}
      </Box>
    </>
  );
};

export default SearchInput;
