import { useQuery } from "react-query";
import { getRequiredDocumentsWithLinkedDocuments } from "../../data-access/required-documents";
import { Divider, Stack, Tooltip } from "@mui/material";
import RequiredDocumentWithLink from "./RequiredDocumentWithLink";
import { useAuth } from "../../contexts/auth";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import AddRequiredDocument from "./AddRequiredDocument";

const RequiredDocumentsSummary = () => {
  const auth = useAuth();

  const { data, error, isLoading } = useQuery(
    ["required-documents-with-linked-documents"],
    () => getRequiredDocumentsWithLinkedDocuments(auth?.selectedCompany?.id!),
    {
      enabled: !!auth?.selectedCompany?.id,
    }
  );

  if (isLoading) return <span>Loading...</span>;

  if (error != null) return <span>Something went wrong</span>;


  return (
    <div>
      <Stack direction="row" alignItems="center" justifyContent="flex-start" gap={1}>
        <h4>Required Documents</h4>
        <Tooltip title="Ensuring your business has the following documents completed and easily accessible is a great starting point">
          <HelpOutlineIcon sx={{ color: "lightgrey" }}></HelpOutlineIcon>
        </Tooltip>
      </Stack>
      {data?.map((item) => {
        return (
          <div key={item.requiredDocument.id}>
            {item.companyRequiredDocument && (
              <RequiredDocumentWithLink item={item}></RequiredDocumentWithLink>
            )}
            {!item.companyRequiredDocument && (
              <AddRequiredDocument requiredDocument={item.requiredDocument}></AddRequiredDocument>
            )}
            <Divider />
          </div>
        );
      })}
    </div>
  );
};

export default RequiredDocumentsSummary;
