import { Button, styled } from "@mui/material";
import {
  RemirrorRenderer,
  Callout,
  CodeBlock,
  Doc,
  Heading,
  createIFrameHandler,
  TextHandler,
  createLinkHandler,
  MarkMap,
} from "@remirror/react";
import React from "react";
import { TextContentRecord } from "../data-access/text-content";
import AdminOnly from "./AdminOnly";

interface RichTextReadOnlyProps {
  textContent: TextContentRecord | undefined;
  setEditable: (editable: boolean) => void;
}
const RichTextReadOnly: React.FC<RichTextReadOnlyProps> = ({ textContent, setEditable }) => {
  const typeMap: MarkMap = {
    blockquote: "blockquote",
    bulletList: "ul",
    callout: Callout,
    codeBlock: CodeBlock,
    doc: Doc,
    hardBreak: "br",
    heading: Heading,
    horizontalRule: "hr",
    iframe: createIFrameHandler(),
    image: "img",
    listItem: "li",
    paragraph: "p",
    orderedList: "ol",
    text: TextHandler,
  };

  const markMap: MarkMap = {
    italic: "em",
    bold: "strong",
    code: "code",
    link: createLinkHandler({ target: "_blank" }),
    underline: "u",
  };

  const RichTextDisplayContainer = styled("div")`
    position: relative;
    padding: 0px 30px;
    max-height: 500px;
    overflow-y: scroll;
    overflow-x: hidden;
    min-height: 50px;
  `;

  const displayJson = textContent?.content ? JSON.parse(textContent.content) : undefined;

  let displayJsonSanitised = undefined;
  if (displayJson) {
    displayJsonSanitised = JSON.parse(
      JSON.stringify(displayJson, (k, v) => (k === "nested" || k === "closed" ? undefined : v))
    );
  }

  return (
    <RichTextDisplayContainer>
      <AdminOnly>
        <Button
          onClick={() => setEditable(true)}
          variant={"outlined"}
          sx={{ position: "absolute", right: "10px", top: "0px" }}
        >
          <i className="fa fa-pencil"></i>
        </Button>
      </AdminOnly>
      {displayJsonSanitised && (
        <RemirrorRenderer json={displayJsonSanitised} typeMap={typeMap} markMap={markMap} />
      )}
    </RichTextDisplayContainer>
  );
};

export default RichTextReadOnly;
