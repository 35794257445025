import React from "react";
import { TextField } from "@mui/material";
import { Control, Controller } from "react-hook-form";

interface PasswordInputProps {
  name: string;
  label?: string;
  control: Control<any, any>;
  rules: any;
  onBlur?: () => void;
}
const PasswordInput: React.FC<PasswordInputProps> = ({ name, label, control, rules, onBlur }) => {
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field, fieldState }) => {
        return (
          <TextField
            label={label}
            name={field.name}
            value={field.value}
            type={"password"}
            onChange={field.onChange}
            onBlur={onBlur}
            sx={{ width: "100%", backgroundColor: "#efefef" }}
          ></TextField>
        );
      }}
    />
  );
};

export default PasswordInput;
