import { Box, Card, Stack } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { getBlogData } from "./data-access/blog-rss";
import { format } from "date-fns";
import { Link } from "react-router-dom";

type BlogItem = {
  title: string;
  publishOn: number;
  assetUrl: string;
  excerpt: string;
  fullUrl: string;
};

type BlogData = {
  items: BlogItem[];
};

const blogRootUrl = `https://www.companyhq.co.nz`;

const BlogRss = () => {
  const [data, setData] = useState<BlogData | undefined>();

  useEffect(() => {
    async function load() {
      const result = (await getBlogData()) as BlogData;
      setData(result);
    }

    load();
  }, []);

  return (
    <Stack gap={2}>
      <h3>Latest from our blog</h3>
      {data &&
        data.items.map((blogItem) => (
          <a
            key={blogItem.fullUrl}
            href={`${blogRootUrl}${blogItem.fullUrl}`}
            target="_blank"
            style={{ textDecoration: "none", color: "#000" }}
          >
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              gap={2}
              sx={{ textDecoration: "none", borderBottom: "1px solid lightgrey" }}
            >
              <Box sx={{ width: "30%" }}>
                <img
                  src={blogItem.assetUrl}
                  style={{
                    width: "100%",
                    height: "150px",
                    backgroundColor: "#AAA",
                    objectFit: "cover",
                  }}
                ></img>
              </Box>
              <Stack sx={{ width: "70%" }}>
                <h4>{blogItem.title}</h4>
                <div dangerouslySetInnerHTML={{ __html: blogItem.excerpt }}></div>
                <p>{format(blogItem.publishOn, "d/M/yy")}</p>
              </Stack>
            </Stack>
          </a>
        ))}
    </Stack>
  );
};

export default BlogRss;
