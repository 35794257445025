const fileNames = {
  "1.0": "1.0 Risk Management.svg",
  1.1: "1.1 Risk Analysis & Matrix.svg",
  1.2: "1.2 Health & Safety.svg",
  "1.2.1": "1.2.1 Health & Safety Policy.svg",
  "1.2.2": "1.2.2 Health & Safety Manual.svg",
  "1.2.3": "1.2.3 Risk Hazard Register.svg",
  "1.2.4": "1.2.4 Accidents Register.svg",
  "1.2.5": "1.2.5 Health & Safety - Other.svg",
  1.3: "1.3 Contractor Requirements.svg",
  1.4: "1.4 Business Continuity Planning.svg",
  1.5: "1.5 Insurance.svg",
  "1.5.1": "1.5.1 Types of Insurance.svg",
  "1.5.2": "1.5.2 Brokers & Insurers.svg",
  "1.5.3": "1.5.3 Insurance Certificates.svg",
  "1.5.4": "1.5.4 Insurance Claims.svg",
  "1.5.5": "1.5.5 Insurance Claims.svg",
  "1.5.6": "1.5.6 Insurance Renewal.svg",
  "1.5.7": "1.5.7 ACC.svg",
  "1.5.8": "1.5.8 Insurance - Other.svg",
  1.7: "1.7 Quality.svg",
  1.8: "1.8 Risk Management - Other.svg",
  "2.0": "2.0 Employment & HR.svg",
  2.1: "2.1 Employment Agreements.svg",
  "2.10": "2.10 Delegated Authority.svg",
  2.11: "2.11 Staff Survey.svg",
  2.12: "2.12 Performance Review.svg",
  2.13: "2.13 Salary Reviews.svg",
  2.14: "2.14 Mentoring.svg",
  2.15: "2.15 Gift Register.svg",
  2.16: "2.16 Employee Share Plan (weak).svg",
  2.17: "2.17 Employment - Other.svg",
  2.2: "2.2 Contractor Agreements.svg",
  2.3: "2.3 Position Descriptions.svg",
  2.4: "2.4 Payroll.svg",
  "2.4.1": "2.4.1 Payroll System.svg",
  "2.4.2": "2.4.2 Timesheet.svg",
  "2.4.3": "2.4.3 Expense Claim.svg",
  "2.4.4": "2.4.4 Mileage Claim.svg",
  "2.4.5": "2.4.5 Leave Request.svg",
  "2.4.6": "2.4.6 Payroll - Other.svg",
  2.5: "2.5 Employee Contact Details.svg",
  2.6: "2.6 Joining Checklist.svg",
  2.7: "2.7 Exit Interview & Checklist.svg",
  2.8: "2.8 Training.svg",
  "2.8.1": "2.8.1 Skills Matrix.svg",
  "2.8.2": "2.8.2 Training Register.svg",
  "2.8.3": "2.8.3 Training - Other.svg",
  2.9: "2.9 Organisation Chart.svg",
  "3.0": "3.0 Agreements and Terms of Trade.svg",
  3.1: "3.1 Agreements.svg",
  "3.1.1": "3.1.1 Agreements Register.svg",
  "3.1.2": "3.1.2 Non-Disclosure (NDA) & Confidentiality Agreements.svg",
  "3.1.3": "3.1.3 Agency & Distributor Agreements.svg",
  "3.1.4": "3.1.4 Leases.svg",
  "3.1.5": "3.1.5 Other Agreements.svg",
  3.2: "3.2 Intellectual Property Protection.svg",
  3.3: "3.3 Terms & Conditions of Sale.svg",
  3.4: "3.4 Tenders Register.svg",
  3.5: "3.5 Incoterms.svg",
  3.6: "3.6 Personal Property Securities Register (weak).svg",
  3.7: "3.7 Legal.svg",
  3.8: "3.8 Agreements - Other.svg",
  "4.0": "4.0 Information Technology.svg",
  4.1: "4.1 Network Diagram.svg",
  4.2: "4.2 Email.svg",
  4.3: "4.3 Backup Procedures.svg",
  4.4: "4.4 Cyber Policy (weak).svg",
  4.5: "4.5 IT Plan.svg",
  4.6: "4.6 IT Register.svg",
  4.7: "4.7 IT - Other.svg",
  "5.0": "5.0 Accounting & Tax.svg",
  5.1: "5.1 Financial Statements.svg",
  "5.1.1": "5.1.1 Accounting System.svg",
  "5.1.2": "5.1.2 Financial Accounts.svg",
  "5.1.3": "5.1.3 Ad Hoc Reports.svg",
  "5.1.4": "5.1.4 Useful Tools.svg",
  "5.1.5": "5.1.5 Accounting - Other.svg",
  "5.10": "5.10 Accounting & Tax - Other.svg",
  5.2: "5.2 Business Planning.svg",
  "5.2.1": "5.2.1 Business Plan.svg",
  "5.2.2": "5.2.2 Budget.svg",
  5.3: "5.3 Tax.svg",
  "5.3.1": "5.3.1 MyIR.svg",
  "5.3.2": "5.3.2 Income Tax Returns.svg",
  "5.3.3": "5.3.3 Tax Pooling.svg",
  "5.3.4": "5.3.4 Transfer Pricing.svg",
  "5.3.5": "5.3.5 GST.svg",
  "5.3.6": "5.3.6 FBT.svg",
  "5.3.7": "5.3.7 Other Taxes.svg",
  "5.3.8": "5.3.8 Tax - Other.svg",
  5.4: "5.4 Banking.svg",
  "5.4.1": "5.4.1 Foreign Exchange.svg",
  "5.4.2": "5.4.2 Facility Agreement.svg",
  "5.4.3": "5.4.3 Signatories.svg",
  "5.4.4": "5.4.4 Security Granted.svg",
  "5.4.5": "5.4.5 Covenants.svg",
  "5.4.6": "5.4.6 Credit Cards.svg",
  "5.4.7": "5.4.7 Letters of Credit.svg",
  "5.4.8": "5.4.8 Performance Bonds & Guarantees.svg",
  "5.4.9": "5.4.9 Banking - Other.svg",
  5.5: "5.5 Debtors.svg",
  "5.5.1": "5.5.1 Invoice Template.svg",
  "5.5.2": "5.5.2 Collecting Overdue Debtors.svg",
  "5.5.3": "5.5.3 Debtors - Other.svg",
  5.6: "5.6 Suppliers - Other.svg",
  "5.6.1": "5.6.1 Asset Register.svg",
  "5.6.2": "5.6.2 Depreciation Rates.svg",
  "5.6.3": "5.6.3 Asset Purchase Request.svg",
  "5.6.4": "5.6.4 Asset Disposal.svg",
  "5.6.5": "5.6.5 Property Valuations.svg",
  "5.6.6": "5.6.6 Property - Other.svg",
  5.7: "5.7 Statistics Returns & Surveys.svg",
  5.8: "5.8 Funding & Grants.svg",
  "5.8.1": "5.8.1 Callaghan Innovation.svg",
  "5.8.2": "5.8.2 New Zealand Export Credit (NZEC).svg",
  "5.8.3": "5.8.3 NZ Trade & Enterprise.svg",
  "5.8.4": "5.8.4 IRD R&D Tax Incentives.svg",
  "5.8.5": "5.8.5 Funding - Other.svg",
  5.9: "5.9 Business Purchase.svg",
  "5.9.1": "5.9.1 Due Diligence Checklist.svg",
  "5.9.2": "5.9.2 Heads of Agreement.svg",
  "5.9.3": "5.9.3 Sale & Purchase Agreement.svg",
  "5.9.4": "5.9.4 Business Purchase - Other.svg",
  "6.0": "6.0 Statutory & Board.svg",
  6.1: "6.1 Company Information Sheet.svg",
  6.2: "6.2 Statutory.svg",
  "6.2.1": "6.2.1 Incorporation.svg",
  "6.2.2": "6.2.2 Constitution.svg",
  "6.2.3": "6.2.3 Companies Office Filings.svg",
  "6.2.4": "6.2.4 Statutory - Other.svg",
  6.3: "6.3 Board.svg",
  "6.3.1": "6.3.1 Director Details.svg",
  "6.3.2": "6.3.2 Board Performance Review.svg",
  "6.3.3": "6.3.3 Balanced Scorecard.svg",
  "6.3.4": "6.3.4 Interests Register.svg",
  "6.3.5": "6.3.5 Board Meetings.svg",
  "6.3.6": "6.3.6 Board - Other.svg",
  6.4: "6.4 Shareholder.svg",
  "6.4.1": "6.4.1 Share Register.svg",
  "6.4.2": "6.4.2 Shareholders Agreement.svg",
  "6.4.3": "6.4.3 Annual Meeting.svg",
  "6.4.4": "6.4.4 Special Meetings.svg",
  "6.4.5": "6.4.5 Audit Opt-Out.svg",
  "6.4.6": "6.4.6 Capital Raising.svg",
  "6.4.7": "6.4.7 Shareholder Updates.svg",
  "6.4.8": "6.4.8 Public Relations.svg",
  "6.4.9": "6.4.9 Shareholder - Other.svg",
  "7.0": "7.0 Sales & Marketing.svg",
  "7.1.1": "7.1.1 CRM.svg",
  "7.1.2": "7.1.2 Sales Proposals & Quotes.svg",
  "7.1.3": "7.1.3 Sales - Other.svg",
  "7.2.1": "7.2.1 Website.svg",
  "7.2.2": "7.2.2 Company Capability & Overview.svg",
  "7.2.3": "7.2.3 Product Brochures.svg",
  "7.2.4": "7.2.4 Services Brochures.svg",
  "7.2.5": "7.2.5 Marketing - Other.svg",
  "7.2.6": "7.2.6 Social Media.svg",
  "7.2.7": "7.2.7 Social Media Guidelines.svg",
  "7.2.8": "7.2.8 Company Newsletter.svg",
  "8.0": "8.0 Operations.svg",
  8.1: "8.1 Project Sheet.svg",
  8.2: "8.2 Process Charts.svg",
  8.3: "8.3 Lean & Six Sigma.svg",
  "8.3.1": "8.3.1 Ideas Hopper.svg",
  "8.3.2": "8.3.2 5S.svg",
  "8.3.3": "8.3.3 Kaizen Events.svg",
  "8.3.4": "8.3.4 A3 Report.svg",
  "8.3.5": "8.3.5 Close Out Report.svg",
  8.4: "8.4 Research & Development.svg",
  8.5: "8.5 Operations - Other.svg",
  "9.0": "9.0 Business & Strategic Planning.svg",
  9.1: "9.1 Business Plan.svg",
  9.2: "9.2 Strategy.svg",
  "9.2.1": "9.2.1 Mission Statement.svg",
  "9.2.2": "9.2.2 Vision Statement.svg",
  "9.2.3": "9.2.3 Values.svg",
  "9.2.4": "9.2.4 Strategic Plan.svg",
  "9.2.5": "9.2.5 Strategy - Other.svg",
  9.3: "9.3 Environmental & Sustainability.svg",
  9.4: "9.4 Pakihi Maori.svg",
  9.5: "9.5 Business & Strategic Planning - Other.svg",
};
export default fileNames;
