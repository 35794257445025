import { Button, Stack } from '@mui/material';
import { useHelpers } from '@remirror/react';
import React from 'react';


interface EditSaveButtonsProps {
    name: string;
    editMode: boolean;
    onSave: ()=>void;
    onEdit: ()=>void;
    onCancel: ()=>void;
}
const EditSaveButtons: React.FC<EditSaveButtonsProps> = ({ editMode, onSave, onEdit, onCancel, name }) => {
    return <Stack direction={'row'} sx={{justifyContent: 'flex-end'}}>
        {!editMode && <Button onClick={onEdit} variant={'outlined'}><i className="fa fa-pencil"></i></Button>}
        {editMode && <Button onClick={onSave} variant={'outlined'}><i className="fa fa-save"></i>Save&nbsp;{name}</Button>}
        {editMode && <Button onClick={onCancel} variant={'outlined'}><i className="fa fa-times"></i></Button    >}
    </Stack>
}

export default EditSaveButtons;