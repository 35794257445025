import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  OverridableCardHeader,
  Stack,
} from "@mui/material";
import RequiredDocument from "../../models/RequiredDocument";
import { useAuth } from "../../contexts/auth";
import SelectFileFromPath from "./SelectFileFromPath";
import { useState } from "react";
import { useMutation, useQuery } from "react-query";
import { setCompanyRequiredDocument } from "../../data-access/required-documents";
import { getCompanyFilesForPath } from "../../data-access/files";
import { useToast } from "../../contexts/toastContext";

type Props = {
  open: boolean;
  setOpen: (newValue: boolean) => void;
  requiredDocument: RequiredDocument;
};

const AddRequiredDocumentModal = ({ open, setOpen, requiredDocument }: Props) => {
  const auth = useAuth();
  const [selectedFileId, setSelectedFileId] = useState<string | null>(null);
  const toast = useToast();

  const {
    data: availableFiles,
    isLoading,
    error,
  } = useQuery(
    ["company-files-for-path", auth?.selectedCompany?.id!, requiredDocument.source_path],
    () => getCompanyFilesForPath(auth?.selectedCompany?.id!, requiredDocument.source_path)
  );

  const setRequiredDocumentMutation = useMutation(setCompanyRequiredDocument, {
    onSuccess: () => {
      toast?.success("Document updated");
      setOpen(false);
    },
    onError: () => {
      toast?.error("Something went wrong");
    },
  });

  if (auth?.selectedCompany?.id == null) {
    console.debug("No company selected");
    return null;
  }

  console.log("selectedFileId", selectedFileId);

  function onCancel() {
    setSelectedFileId(null);
    setOpen(false);
  }

  function onSave() {
    if (auth?.selectedCompany?.id == null) {
      throw Error("No company selected");
    }

    const selectedFile = availableFiles?.find((f) => f.id === selectedFileId);

    if (selectedFile?.name == null) {
      console.error("No file selected");
      alert("No file selected");
      return;
    }

    debugger;

    const fullFilePath = `${requiredDocument.source_path}/${selectedFile.name}`;

    setRequiredDocumentMutation.mutate({
      companyId: auth.selectedCompany?.id,
      requiredDocumentId: requiredDocument.id,
      companyRequiredDocumentPath: fullFilePath,
    });
  }

  return (
    <Dialog open={open} fullWidth={true} maxWidth="lg">
      <DialogTitle>Add document</DialogTitle>
      <DialogContent>
        <p>
          A copy of <b>{requiredDocument.name}</b> hasn't been selected for your company yet.
        </p>
        <p>
          If it has already been uploaded to CompanyHQ you can find it in the list below and click
          save
        </p>
        <p>
          <Stack direction="row">
            <Stack>
              {availableFiles != null && (
                <SelectFileFromPath
                  availableFiles={availableFiles}
                  selectedFileId={selectedFileId}
                  setSelectedFileId={setSelectedFileId}
                ></SelectFileFromPath>
              )}
            </Stack>
          </Stack>
        </p>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>Cancel</Button>
        <Button
          disabled={selectedFileId === null || setRequiredDocumentMutation.isLoading}
          variant="contained"
          onClick={onSave}
        >
          {setRequiredDocumentMutation.isLoading && <CircularProgress></CircularProgress>}
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddRequiredDocumentModal;
