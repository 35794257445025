import { List, ListItem, ListItemButton, ListItemText } from "@mui/material";
import { FileObject } from "@supabase/storage-js";

type Props = {
  availableFiles: FileObject[] | null;
  selectedFileId: string | null;
  setSelectedFileId: (newValue: string) => void;
};

const SelectFileFromPath = ({ availableFiles, selectedFileId, setSelectedFileId }: Props) => {
  return (
    <List>
      {availableFiles?.map((file) => {
        return (
          <ListItem>
            <ListItemButton
              selected={selectedFileId === file.id}
              onClick={() => setSelectedFileId(file.id)}
            >
              <ListItemText primary={file.name} />
            </ListItemButton>
          </ListItem>
        );
      })}
    </List>
  );
};

export default SelectFileFromPath;
