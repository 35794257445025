import React from "react";

interface OfficeDocViewerProps {
  docUrl: string | null;
}
const OfficeDocViewer: React.FC<OfficeDocViewerProps> = ({ docUrl }) => {

  if (!docUrl) {
    return <div>Loading...</div>;
  }
  const viewerUrl = `https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(
    docUrl
  )}`;
  return (
    <iframe title="Document Viewer" src={viewerUrl} width="900px" height="623px">
      This is an embedded{" "}
      <a target="_blank" href="http://office.com">
        Microsoft Office
      </a>{" "}
      document, powered by{" "}
      <a target="_blank" href="http://office.com/webapps">
        Office Online
      </a>
      .
    </iframe>
  );
};

export default OfficeDocViewer;
