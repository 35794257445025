import { Alert, IconButton, Snackbar } from "@mui/material";
import React, { createContext, useContext, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";

declare type notificationLevel = "success" | "warning" | "error";

interface ToastDetails {
  level: notificationLevel;
  message: string;
}

interface ToastContextValue {
  success: (message: string) => void;
  warning: (message: string) => void;
  error: (message: string) => void;
}

export const ToastContext = createContext<ToastContextValue>({
  success: () => null,
  warning: () => null,
  error: () => null,
});

export const ToastContextProvider: React.FC = (props) => {
  const [notification, setNotification] = useState<ToastDetails | null>(null);
  const [open, setOpen] = useState<boolean>(false);

  function success(message: string) {
    setNotification({ level: "success", message: message });
    setOpen(true);
  }

  function warning(message: string) {
    setNotification({ level: "warning", message: message });
    setOpen(true);
  }

  function error(message: string) {
    setNotification({ level: "error", message: message });
    setOpen(true);
  }

  const action = (
    <IconButton size="small" aria-label="close" color="inherit" onClick={() => setOpen(false)}>
      <CloseIcon fontSize="small" />
    </IconButton>
  );

  return (
    <ToastContext.Provider value={{ success, warning, error }}>
      <Snackbar
        open={open}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        autoHideDuration={6000}
        onClose={() => setOpen(false)}
        action={action}
      >
        <Alert severity={notification?.level || "info"} sx={{ width: "100%" }}>
          {notification?.message}
        </Alert>
      </Snackbar>
      {props.children}
    </ToastContext.Provider>
  );
};

export function useToast() {
  return useContext<ToastContextValue>(ToastContext);
}