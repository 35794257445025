import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Alert, Box, Button, Card, Container, Stack, TextField } from "@mui/material";
import { supabaseClient } from "../util/supabase";
import { AuthProvider, useAuth } from "../contexts/auth";

interface SetPasswordForm {
  password: string;
  confirmPassword: string;
}

const SetPasswordPage: React.FC = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm<SetPasswordForm>();
  const navigate = useNavigate();

  const rules = {
    password: {
      required: "Please enter a new password",
      minLength: {
        value: 6,
        message: "Password must be at least 6 characters",
      },
    },
    confirmPassword: {
      validate: (value: string) => {
        return value === getValues("password") || "Password fields must match";
      },
    },
  };

  const auth = useAuth();

  const onSubmit = async (formData: SetPasswordForm) => {
    const { data, error } = await supabaseClient.auth.updateUser({ password: formData.password }); // Error: Not logged in ??
    if (data && !error) {
      navigate("/");
    } else {
      console.error(error);
      alert("Something went wrong");
    }
  };

  return (
    <Container maxWidth="md">
      <Box sx={{ my: 4 }}>
          <Card sx={{ padding: "20px" }}>
            <Stack direction={"column"} alignItems={"center"}>
              <img src={"/img/blue_gold_landscape.svg"} width={400} height={150}></img>
              <h4>Choose a new password</h4>

              <form style={{ width: "500px" }} onSubmit={handleSubmit(onSubmit)}>
                <Stack spacing={3}>
                  <TextField
                    type="password"
                    {...register("password", rules.password)}
                    label="Password"
                    variant="outlined"
                  />
                  {errors.password && <Alert severity="error">{errors.password?.message}</Alert>}
                  <TextField
                    type="password"
                    {...register("confirmPassword", rules.confirmPassword)}
                    label="Confirm password"
                    variant="outlined"
                  />
                  {errors.confirmPassword && (
                    <Alert severity="error">{errors.confirmPassword?.message}</Alert>
                  )}
                  <Button type={"submit"} variant="contained">
                    Confirm
                  </Button>
                </Stack>
              </form>
            </Stack>
          </Card>
      </Box>
    </Container>
  );
};

export default SetPasswordPage;
