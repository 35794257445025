import { Checkbox, FormControlLabel } from "@mui/material";
import RequiredDocument from "../../models/RequiredDocument";
import { useState } from "react";
import AddRequiredDocumentModal from "./AddRequiredDocumentModal";

type Props = {
  requiredDocument: RequiredDocument;
};

const AddRequiredDocument = ({ requiredDocument }: Props) => {
  const [addDocumentModalOpen, setAddDocumentModalOpen] = useState(false);

  return (
    <>
      <FormControlLabel
        label={requiredDocument.name}
        sx={{ cursor: "pointer" }}
        control={
          <Checkbox checked={false} style={{ pointerEvents: "none", color: "#090" }}></Checkbox>
        }
        onClick={() => setAddDocumentModalOpen(true)}
      ></FormControlLabel>
      <AddRequiredDocumentModal
        open={addDocumentModalOpen}
        setOpen={setAddDocumentModalOpen}
        requiredDocument={requiredDocument}
      ></AddRequiredDocumentModal>
    </>
  );
};

export default AddRequiredDocument;
