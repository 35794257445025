import CompanyRequiredDocument from "../models/CompanyRequiredDocument";
import RequiredDocument from "../models/RequiredDocument";
import { supabaseClient } from "../util/supabase";

export type RequiredDocumentWithLinkedDocument = {
  requiredDocument: RequiredDocument;
  companyRequiredDocument?: CompanyRequiredDocument;
};

export async function getRequiredDocuments() {
  const { data, error } = await supabaseClient.from("required_document").select();

  if (error != null) {
    console.error(error);
    throw Error("Failed to load required documents");
  }

  return data as RequiredDocument[];
}

export async function getCompanyRequiredDocuments(companyId: string) {
  const { data, error } = await supabaseClient
    .from("company_required_document")
    .select()
    .eq("company_id", companyId);

  if (error != null) {
    console.error(error);
    throw Error("Failed to load required documents");
  }

  return data as CompanyRequiredDocument[];
}

export async function getRequiredDocumentsWithLinkedDocuments(companyId: string) {
  const requiredDocuments = await getRequiredDocuments();
  const companyRequiredDocuments = await getCompanyRequiredDocuments(companyId);

  const requiredDocumentsWithLinkedDocuments =
    requiredDocuments.map<RequiredDocumentWithLinkedDocument>((reqDoc) => {
      const linkedDocument = companyRequiredDocuments.find(
        (comReqDoc) => comReqDoc.required_document_id === reqDoc.id
      );
      return {
        requiredDocument: reqDoc,
        companyRequiredDocument: linkedDocument,
      };
    });

  return requiredDocumentsWithLinkedDocuments;
}

export type SetCompanyRequiredDocumentParams = {
  companyId: string;
  requiredDocumentId: string;
  companyRequiredDocumentPath: string;
};

export async function setCompanyRequiredDocument({
  companyId,
  requiredDocumentId,
  companyRequiredDocumentPath,
}: SetCompanyRequiredDocumentParams) {
  const { data, error } = await supabaseClient.from("company_required_document").insert({
    company_id: companyId,
    required_document_id: requiredDocumentId,
    path: companyRequiredDocumentPath,
  });

  if (error != null) {
    console.error(error);
    throw Error("Failed to set required documents");
  }

  return;
}
