import { posix } from './path';

const urlPrefix = '/docs';

export function getFilePathFromUrl(url: string){
    if (url.startsWith(urlPrefix))
    {
        return url.slice(urlPrefix.length);
    }
    else{
        throw Error("Url doesn't start with /docs");
    }
}

// export function getParentPath(path: string, prefix: string){
//     if (path.length > 1 && path[path.length -1] === '/'){
//         path = path.slice(0, -1);
//     }
//     let to = path.lastIndexOf('/');
//     if (to === 0){
//         return prefix;
//     }
//     else{
//         if (to === -1){
//             to = path.length;
//         }
//     }
//     path = path.substring(0, to);
//     return prefix + path;
// }

export function getParentPath(dirPath: string, prefix: string){
    const fullPath = posix.join([prefix, dirPath]);
    return posix.dirname(fullPath);
}