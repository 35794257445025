import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { ToastContext, useToast } from "../contexts/toastContext";
import {
  FileObjectWithSource,
  getFileNamesAtPathRecursive,
  updateNestedLinksContent,
  updateNestedTextContent,
} from "../data-access/directory";
import StorageBucket from "../models/StorageBucket";
import { getTrimmedBaseUrl, moveFile } from "../util/files";
import { posix } from "../util/path";

const RenameFileModal: React.FC<{
  fileObject: FileObjectWithSource;
  baseUrl: string[];
  storageBucket: StorageBucket;
  companyId?: string;
  open: boolean;
  handleCancel: () => void;
  handleRenameComplete: () => void;
}> = ({
  fileObject,
  baseUrl,
  storageBucket,
  companyId,
  open,
  handleCancel,
  handleRenameComplete,
}) => {
  const toast = useToast();
  const [paths, setPaths] = useState<Set<string>>(new Set<string>());
  const [renaming, setRenaming] = useState(false);

  let fullyQualifiedBaseUrl = "";
  if (storageBucket === StorageBucket.CoreContent) {
    const pathSegments = getTrimmedBaseUrl(baseUrl);
    fullyQualifiedBaseUrl = posix.join(pathSegments);
  } else {
    fullyQualifiedBaseUrl = posix.join([companyId, ...baseUrl]);
  }

  const [newName, setNewName] = useState<string>(fileObject.name);

  useEffect(() => {
    async function assembleArrayOfFiles() {
      if (fileObject.id) {
        setPaths(new Set<string>([posix.join([fullyQualifiedBaseUrl, fileObject.name])]));
      } else {
        const filePath = posix.join([fullyQualifiedBaseUrl, fileObject.name]);
        console.log("RenameFileModal");
        const filePaths = await getFileNamesAtPathRecursive(filePath, storageBucket);
        setPaths(filePaths);
      }
    }
    assembleArrayOfFiles();
  }, []);

  async function onConfirmRename() {
    setRenaming(true);

    const originalBasePath = posix.join([fullyQualifiedBaseUrl, fileObject.name]);
    const newBasePath = posix.join([fullyQualifiedBaseUrl, newName]);

    if (fileObject.id == null) {
      const newFolderPathSegment = posix.join([fullyQualifiedBaseUrl, newName]);
      let index = 0;
      for (const path of paths) {
        index++;
        const nestedPath = path.slice(originalBasePath.length);
        const newPath = posix.join([newFolderPathSegment, nestedPath]);
        try {
          await moveFile(path, newPath, storageBucket);
          toast?.success(`Updated ${index} of ${paths.size} files`);
        } catch (err) {
          toast?.error(`Failed to rename ${path}`);
        }
      }

      await updateNestedTextContent(originalBasePath, newBasePath);
      await updateNestedLinksContent(originalBasePath, newBasePath);
    } else {
      moveFile(originalBasePath, newBasePath, storageBucket);
    }

    setRenaming(false);
    handleRenameComplete();
  }

  return (
    <Dialog open={open} onClose={handleCancel}>
      <DialogTitle>Rename {fileObject.id == null ? "folder" : "file"}</DialogTitle>
      <DialogContent>
        <TextField
          value={newName}
          onChange={(event) => setNewName(event.target.value)}
          style={{ width: "350px" }}
        ></TextField>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} variant={"outlined"} disabled={renaming}>
          Cancel
        </Button>
        <Button onClick={onConfirmRename} color={"error"} variant={"contained"} disabled={renaming}>
          Rename
          {renaming && <CircularProgress></CircularProgress>}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RenameFileModal;
