import { Stack } from "@mui/material";
import CompanyModel from "../../../models/CompanyModel";
import { useEffect, useState } from "react";
import { getCompanyLogoUrlIfExists } from "../data-access/companyLogo";

type Props = {
  company: CompanyModel;
};

const CompanyDetailsReadOnly = ({ company }: Props) => {
  const [logoUrl, setLogoUrl] = useState<string | undefined>("");

  useEffect(() => {
    async function assignLogoUrl() {
      if (company.logo_path == null){
        setLogoUrl("");
        return;
      }

      const logoUrlResult = await getCompanyLogoUrlIfExists(company.logo_path);
      if (logoUrlResult != null) {
        setLogoUrl(logoUrlResult);
      }
    }
    assignLogoUrl();
  }, [company]);
  return (
    <Stack direction={"column"} gap={1}>
      <p>
        {logoUrl && <img key={logoUrl} id={logoUrl} src={logoUrl} style={{ maxHeight: "200px", width: "auto", maxWidth: "500px" }}></img>}
      </p>
      <p>
        Trading name: <b>{company.trading_name}</b>
      </p>
      <p>
        Phone number: <b>{company.phone_number}</b>
      </p>
      <p>
        Contact name: <b>{company.contact_name}</b>
      </p>
      <p>
        Contact email: <b>{company.contact_email}</b>
      </p>
      <p>
        NZBN: <b>{company.nzbn}</b>
      </p>
      <p>
        Office address: <b>{company.office_address}</b>
      </p>
      <p>
        Postal address: <b>{company.postal_address}</b>
      </p>
      <p>
        IRD Number: <b>{company.ird_number}</b>
      </p>
      <p>
        Web Address: <b>{company.web_address}</b>
      </p>
      <p>
        LinkedIn: <b>{company.social_linkedin}</b>
      </p>
      <p>
        Facebook: <b>{company.social_facebook}</b>
      </p>
      <p>
        Twitter: <b>{company.social_twitter}</b>
      </p>
      <p>
        Instagram: <b>{company.social_instagram}</b>
      </p>
    </Stack>
  );
};

export default CompanyDetailsReadOnly;
