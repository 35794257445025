import { supabaseClient } from "../../../util/supabase";
import CompanyModel from "../../../models/CompanyModel";

export async function getCompanies() {
  const { data, error } = await supabaseClient.functions.invoke<CompanyModel[]>("get-companies");

  if (error != null) {
    throw Error(error.message);
  } else {
    return data;
  }
}

export async function getUsersForCompany(companyId: string) {
  const requestBody = {
    companyid: companyId,
  };

  const { data, error } = await supabaseClient.functions.invoke("get-users-for-company", {
    body: JSON.stringify(requestBody),
  });

  if (error != null) {
    console.error(error);
    throw Error(error.message);
  }

  return data;
}

export async function getUserById(userid: string) {
  const requestBody = {
    userid: userid,
  };
  const { data, error } = await supabaseClient.functions.invoke<CompanyModel[]>("get-user-by-id", {
    body: JSON.stringify(requestBody),
  });

  if (error != null) {
    throw Error(error.message);
  } else {
    return data;
  }
}

export async function inviteUserToCompany(email: string, companyId: string) {
  const requestBody = {
    email: email,
    companyId: companyId,
  };
  const { data, error } = await supabaseClient.functions.invoke<{ message: string }>(
    "invite-user-to-company",
    {
      body: JSON.stringify(requestBody),
    }
  );

  if (error != null) {
    throw Error(error.message);
  } else {
    return data;
  }
}

export async function removeUserFromCompany(email: string, companyId: string) {
  const requestBody = {
    email: email,
    companyId: companyId,
  };

  const { data, error } = await supabaseClient.functions.invoke<{ message: string }>(
    "remove-user-from-company",
    {
      body: JSON.stringify(requestBody),
    }
  );

  if (error != null) {
    throw Error(error.message);
  } else {
    return data;
  }
}
