import { styled } from "@mui/material";

const StyledHr = styled('hr')`
    color: #ddd;
    height: 1px;
    border-top: none;
    border-right: none;
    border-left: none;
`;

export default StyledHr;