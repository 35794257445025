import { FileObjectWithSource } from "../data-access/directory";
import StorageBucket from "../models/StorageBucket";
import { supabaseClient } from "../util/supabase";
import { getFolderNumberPrefix, getRecyclePath, getRestorePath, posix } from "./path";

export function sortFilesByVersionNumbers(files: FileObjectWithSource[]): FileObjectWithSource[] {
  return files.sort((a, b) => {
    const aMatch = a.name.match(/^(\d+(?:\.\d+)*).*$/);
    const bMatch = b.name.match(/^(\d+(?:\.\d+)*).*$/);

    // If either or both strings do not start with a version number, fall back to alphabetical sorting
    if (!aMatch || !bMatch) {
      return a.name.localeCompare(b.name);
    }

    const aParts = aMatch[1].split(".").map(Number);
    const bParts = bMatch[1].split(".").map(Number);

    for (let i = 0; i < Math.max(aParts.length, bParts.length); i++) {
      const aPart = i < aParts.length ? aParts[i] : 0;
      const bPart = i < bParts.length ? bParts[i] : 0;

      if (aPart !== bPart) {
        return aPart - bPart;
      }
    }

    // If version numbers are equal, fall back to alphabetical sorting
    return a.name.localeCompare(b.name);
  });
}

export function isFile(fileObject: FileObjectWithSource) {
  return !!fileObject.id;
}

export function getFileExtensionFromName(name: string) {
  const pathSegments = name.split(".");
  if (pathSegments.length < 2) {
    return "";
  } else {
    return pathSegments[pathSegments.length - 1];
  }
}

export function removeLeadingSlashes(input: string) {
  let noLeadingSlash = "";
  if (input && input.length > 0) {
    noLeadingSlash = input;
    while (noLeadingSlash && noLeadingSlash.length > 0 && noLeadingSlash[0] === "/") {
      noLeadingSlash = noLeadingSlash.substring(1, noLeadingSlash.length);
    }
  }
  return noLeadingSlash;
}

export async function downloadFile(
  openInNewTab: boolean,
  fileName: string,
  path: string[],
  source: StorageBucket,
  selectedCompanyId: string | undefined
) {
  let pathWithCompanyIdentifier = path;
  if (
    pathWithCompanyIdentifier.length > 0 &&
    selectedCompanyId != null &&
    pathWithCompanyIdentifier[0] !== selectedCompanyId &&
    source === StorageBucket.UserContent
  ) {
    pathWithCompanyIdentifier.splice(0, 0, selectedCompanyId);
  }
  const signedUrl = await getSignedUrlForFile(
    fileName,
    pathWithCompanyIdentifier,
    source,
    selectedCompanyId
  );
  if (openInNewTab) {
    if (signedUrl) {
      window.open(signedUrl, "_blank");
    } else {
      console.error("Signed URL is empty or null");
      return;
    }
  } else {
    // @ts-ignore
    linkRef.current.setAttribute("href", signedUrl);
    // @ts-ignore
    linkRef.current.setAttribute("download", dir.name);
    // @ts-ignore
    linkRef.current.click();
  }
}

export async function recycleFile(
  path: string,
  storageBucket: StorageBucket,
  selectedCompany: string | undefined
) {
  const recyclePath = getRecyclePath(path, storageBucket, selectedCompany);

  const { data, error } = await supabaseClient.storage.from(storageBucket).move(path, recyclePath);

  if (error) {
    throw Error(error.message);
  }

  if (data == null) {
    throw Error(`No files deleted, possible path not found for ${path}`);
  }
}

export async function permanentlyDeleteFile(path: string, storageBucket: StorageBucket) {
  // const recyclePath = getRecyclePath(path, storageBucket, selectedCompany);

  const { data, error } = await supabaseClient.storage.from(storageBucket).remove([path]);

  if (error) {
    throw Error(error.message);
  }

  if (data == null) {
    throw Error(`No files deleted, possible path not found for ${path}`);
  }
}

export async function restoreFile(path: string, storageBucket: StorageBucket) {
  const restorePath = getRestorePath(path);

  const { data, error } = await supabaseClient.storage.from(storageBucket).move(path, restorePath);

  if (error) {
    throw Error(error.message);
  }

  if (data == null) {
    throw Error(`No files deleted, possible path not found for ${path}`);
  }
}

export async function moveFile(oldPath: string, newPath: string, storageBucket: StorageBucket) {
  const { data, error } = await supabaseClient.storage.from(storageBucket).move(oldPath, newPath);

  if (error) {
    throw Error(error.message);
  }
}

export async function getSignedUrlForFile(
  fileName: string,
  path: string[],
  source: StorageBucket,
  selectedCompany: string | undefined
) {
  let filePath = posix.join([...path, fileName]);

  const filePathNoLeadingSlashes = removeLeadingSlashes(filePath);

  const { data, error } = await supabaseClient.storage
    .from(source)
    .createSignedUrl(filePathNoLeadingSlashes, 60);

  if (error) {
    console.error(error);
    throw Error(error.toString());
  } else {
    return data.signedUrl;
  }
}

export function getFullFilePath(
  basePath: string[],
  fileName: string,
  storageBucket: StorageBucket,
  companyId?: string
) {
  switch (storageBucket) {
    case StorageBucket.CoreContent:
      return getFilePathForCoreContent(basePath, fileName);
    case StorageBucket.UserContent:
      if (companyId == null)
        throw Error("Company id must be provided when working with user content");
      return getFilePathWithCompanyIdentity(basePath, fileName, companyId);
    default:
      throw Error(`Unhandled storage bucket type ${storageBucket}`);
  }
}

export function getFilePathWithCompanyIdentity(
  basePath: string[],
  relativeFilePath: string,
  companyId: string
): string {
  const filePath = posix.join([companyId, ...basePath, relativeFilePath]);
  return filePath;
}

export function getFilePathForCoreContent(basePath: string[], relativeFilePath: string): string {
  const filePath = posix.join([...basePath, relativeFilePath]);
  return filePath;
}

export function getTrimmedBaseUrl(pathSegments: string[]): string[] {
  while (pathSegments.length > 0 && (pathSegments[0] === "docs" || pathSegments[0] === "")) {
    pathSegments.shift();
  }

  return pathSegments;
}
