import React, { useContext, useRef, useState } from "react";
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import { supabaseClient } from "../util/supabase";
import FileUploadProgress from "./FileUploadProgress";
import { useAuth } from "../contexts/auth";
import AddIcon from "@mui/icons-material/Add";
import { getFullFilePath } from "../util/files";
import { posix } from "../util/path";
import ButtonMenu, { MenuItemDefinition } from "./ButtonMenu";
import StorageBucket from "../models/StorageBucket";
import { ToastContext, useToast } from "../contexts/toastContext";
import { emptyFolderPlaceholderName } from "../constants";

const filesToSkip: string[] = [".DS_Store"];

interface FileUploadProps {
  currentPath: string[];
  storageBucket: StorageBucket;
  reloadData: () => void;
}
const FileUpload: React.FC<FileUploadProps> = ({ currentPath, storageBucket, reloadData }) => {
  const toast = useToast();
  const [currentFileName, setCurrentFileName] = useState("");
  const [totalFiles, setTotalFiles] = useState(0);
  const [currentFileIndex, setCurrentFileIndex] = useState(0);
  const auth = useAuth();
  const [uploadInProgress, setUploadInProgress] = useState(false);
  const [creatingFolder, setCreatingFolder] = useState<boolean>(false);

  const uploadFilesRef = useRef<HTMLInputElement>(null);
  const uploadFilesElementId = `upload-files-${storageBucket}`;
  const uploadDirectoryElementId = `upload-directory-${storageBucket}`;

  async function uploadFiles() {
    // @ts-ignore
    const files = document.getElementById(uploadFilesElementId).files;
    if (files == null || files.length === 0) {
      console.error("No array of files found", files);
      toast?.error("Something went wrong, we couldn't upload your files");
      return;
    }

    for (const file of files) {
      const filePath = await getFullFilePath(
        currentPath,
        file.name,
        storageBucket,
        auth?.selectedCompany?.id
      );

      const sanitisedPath = filePath.replace("+", ""); //remove any + symbols as they cause bugs with AWS S3 storage

      try {
        const { data, error } = await supabaseClient.storage
          .from(storageBucket)
          .upload(sanitisedPath, file, {
            cacheControl: "3600",
            upsert: false,
          });
        if (error != null) {
          console.error(error);
          if ((error as any).statusCode === "403") {
            toast?.error("You don't have upload permission");
          } else {
            toast?.error("Upload failed");
          }
          return;
        }
        toast?.success("Upload complete");
        reloadData();
      } catch (e: any) {
        toast?.error("Upload failed");
        console.error(e);
      } finally {
        setUploadInProgress(false);
        //   setUploadDialogOpen(false);
      }
    }
  }

  async function uploadDirectory() {
    setUploadInProgress(true);
    // @ts-ignore
    let files: any[] = Array.from(document.getElementById(uploadDirectoryElementId).files);
    files = files.filter((x) => !filesToSkip.includes(x.name as string));
    setTotalFiles(files.length);
    for (var i = 0; i < files.length; i++) {
      try {
        setCurrentFileName(files[i].name);
        setCurrentFileIndex(i);
        const filePath = await getFullFilePath(
          currentPath,
          files[i].webkitRelativePath,
          storageBucket,
          auth?.selectedCompany?.id
        );

        const { data, error } = await supabaseClient.storage
          .from(storageBucket)
          .upload(filePath, files[i], {
            cacheControl: "3600",
            upsert: false,
          });

        if (error != null) {
          console.error(error);
          if ((error as any).statusCode === "403") {
            toast?.error("You don't have upload permission");
          } else {
            toast?.error("Upload failed");
          }
          return;
        }
      } catch (e: any) {
        toast?.error("Upload failed");
        console.error(e);
      }
    }
    setUploadInProgress(false);
    // setUploadDialogOpen(false);
    reloadData();
  }

  const webkitDirRef = useRef<HTMLInputElement>(null);

  React.useEffect(() => {
    if (webkitDirRef.current !== null) {
      webkitDirRef.current.setAttribute("directory", "");
      webkitDirRef.current.setAttribute("webkitdirectory", "");
    }
  }, [webkitDirRef]);

  const menuItems: MenuItemDefinition[] = [
    {
      label: "Upload Files",
      onClick: () => uploadFilesRef.current?.click(),
    },
    {
      label: "Upload Folder",
      onClick: () => webkitDirRef.current?.click(),
    },
    {
      label: "New Folder",
      onClick: () => setCreatingFolder(true),
    },
  ];

  return (
    <>
      <ButtonMenu
        buttonContent={<AddIcon></AddIcon>}
        menuItems={menuItems}
        tooltip="Upload content"
        standalone={true}
      ></ButtonMenu>
      <input
        type="file"
        id={uploadFilesElementId}
        ref={uploadFilesRef}
        multiple
        onChange={uploadFiles}
        style={{ display: "none" }}
      ></input>
      <input
        type="file"
        id={uploadDirectoryElementId}
        ref={webkitDirRef}
        onChange={uploadDirectory}
        style={{ display: "none" }}
      ></input>
      {uploadInProgress && (
        <FileUploadProgress
          fileName={currentFileName}
          index={currentFileIndex}
          total={totalFiles}
        ></FileUploadProgress>
      )}
      <CreateFolder
        open={creatingFolder}
        closeModal={() => setCreatingFolder(false)}
        currentPath={currentPath}
        reloadData={reloadData}
        storageBucket={storageBucket}
      />
    </>
  );
};

const CreateFolder: React.FC<{
  open: boolean;
  closeModal: () => void;
  currentPath: string[];
  storageBucket: StorageBucket;
  reloadData: () => void;
}> = ({ open, closeModal, currentPath, reloadData, storageBucket }) => {
  const [folderName, setFolderName] = useState("");
  const [error, setError] = useState<string>("");
  const auth = useAuth();
  const toast = useToast();

  async function onCreateFolder() {
    if (!folderName || folderName.length < 1) {
      setError("Please enter a name for the new folder");
    } else {
      const companyId = auth?.selectedCompany?.id;
      if (!companyId) {
        toast?.error("An error occurred");
        console.error("No company selected");
      } else {
        const path = getFullFilePath(
          currentPath,
          posix.join([folderName, emptyFolderPlaceholderName]),
          storageBucket,
          auth?.selectedCompany?.id
        );
        createFolder(path);
        closeModal();
      }
    }
  }

  async function createFolder(folderPath: string) {
    try {
      const { data, error } = await supabaseClient.storage
        .from(storageBucket)
        .upload(folderPath, "", {
          cacheControl: "3600",
          upsert: false,
        });

      if (error != null) {
        console.error(error);
        if ((error as any).statusCode === "403") {
          toast?.error("You don't have permission to create a folder");
        } else {
          toast?.error("There was a problem creating your folder");
        }
        return;
      }

      toast?.success(`New folder created`);
      reloadData();
    } catch (e: any) {
      toast?.error("There was a problem creating your folder");
      console.error(e);
    } finally {
      closeModal();
      setFolderName("");
    }
  }

  return (
    <Dialog open={open} maxWidth={"sm"}>
      <DialogTitle>New folder name</DialogTitle>
      <DialogContent>
        <TextField
          value={folderName}
          onChange={(event) => setFolderName(event.target.value)}
          style={{ width: "350px" }}
        ></TextField>
        {error && <Alert severity={"error"}>{error}</Alert>}
      </DialogContent>
      <DialogActions>
        <Button onClick={closeModal}>Close</Button>
        <Button onClick={onCreateFolder} variant={"contained"}>
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FileUpload;
