import { Card, CardContent, Stack } from "@mui/material";
import React, { useState, useEffect } from "react";
import { FileObjectWithSource, getDirectoriesForPath } from "../data-access/directory";
import { getParentPath } from "../util/url";
import { documentPathPrefix } from "../util/constants";
import DisplayGrid from "./DisplayGrid";
import DisplayTable from "./DisplayTable";
import CircularProgress from "@mui/material/CircularProgress";
import { DisplayMode } from "../models/DisplayMode";
import RichTextDisplay from "./RichTextDisplay";
import { useAuth } from "../contexts/auth";
import NavBreadcrumb from "./NavBreadcrumb";
import SubcategoryTiles from "./SubcategoryTiles";

interface DocsDisplayProps {
  url: string;
  defaultDisplayMode: DisplayMode;
}
export const DocsDisplay: React.FC<DocsDisplayProps> = ({ url, defaultDisplayMode }) => {
  const [displayMode, setDisplayMode] = useState<DisplayMode>(defaultDisplayMode);
  const [loading, setLoading] = useState<boolean>(true);
  const [coreItems, setCoreItems] = useState<FileObjectWithSource[] | undefined>();
  const [userItems, setUserItems] = useState<FileObjectWithSource[] | null>();
  const [isLeafNode, setIsLeafNode] = useState<boolean>(false);
  const [parentDir, setParentDir] = useState<string>("");
  const ref = React.useRef<HTMLInputElement>(null);
  const auth = useAuth();

  useEffect(() => {
    if (ref.current !== null) {
      ref.current.setAttribute("directory", "");
      ref.current.setAttribute("webkitdirectory", "");
      ref.current.setAttribute("mozdirectory", "");
    }
  }, [ref]);

  useEffect(() => {
    setLoading(true);
    const parentPath = getParentPath(url, documentPathPrefix);
    setParentDir(parentPath);

    if (url) {
      console.log("if url");
      loadData();
    }
  }, [url]);

  useEffect(
    function reloadDataOnSelectedCompanyChange() {
      console.log("reloadDataOnSelectedCompanyChange");
      loadData();
    },
    [auth.selectedCompany]
  );

  async function loadData() {
    console.log("LoadData");
    if (!auth?.selectedCompany?.id) throw Error("No company id set");
    const fetchedDirs = await getDirectoriesForPath(url, auth?.selectedCompany?.id);
    if (fetchedDirs != null) {
      setCoreItems(fetchedDirs.coreData);
      setUserItems(fetchedDirs.userData);
      setIsLeafNode(fetchedDirs.isLeafNode);
    }
    setLoading(false);
  }

  async function reloadData() {
    console.log("Reload data");
    await loadData();
  }


  const handleAlignment = (event: any, newDisplayMode: DisplayMode) => {
    setDisplayMode(newDisplayMode);
  };

  const displayCoreItems = (coreItems && coreItems.length > 0) || auth.isAdmin;

  const pathSegments = url.split("/").filter(x => x != null && x !== "");

  let readPermission =
    auth?.permissions?.find((x) => x.path === pathSegments[0] && x.read_permission) != null;
  let writePermission =
    auth?.permissions?.find((x) => x.path === pathSegments[0] && x.write_permission) != null;

  if (auth?.isAdmin) {
    readPermission = true;
    writePermission = true;
  }

  return (
    <Stack direction={"column"} gap={3}>
      <Card>
        <CardContent>
          <NavBreadcrumb></NavBreadcrumb>
          <RichTextDisplay path={url}></RichTextDisplay>
        </CardContent>
      </Card>
      {loading && <CircularProgress></CircularProgress>}
      {!loading && displayCoreItems && (
        <SubcategoryTiles
          dirs={coreItems}
          baseUrl={url.split("/")}
          reloadData={reloadData}
        ></SubcategoryTiles>
      )}
      {!loading && displayMode === DisplayMode.Grid && userItems && (
        <DisplayGrid dirs={userItems} path={url}></DisplayGrid>
      )}
      {!loading && displayMode === DisplayMode.Table && userItems && (
        <>
          {!readPermission && <h4>No access to view files</h4>}
          {readPermission && (
            <DisplayTable
              dirs={userItems}
              path={pathSegments}
              reloadData={reloadData}
              isLeafNode={isLeafNode}
              readPermission={readPermission}
              writePermission={writePermission}
            ></DisplayTable>
          )}
        </>
      )}
    </Stack>
  );
};
