import { MenuItem, Select } from "@mui/material";
import { StoragePermission } from "./types";

type PermissionDropdownProps = {
  value: StoragePermission;
  onChange: (newValue: StoragePermission) => void;
};

const PermissionDropdown = ({ value, onChange }: PermissionDropdownProps) => {
  return (
    <Select value={value} onChange={(e) => onChange(e.target.value as StoragePermission)}>
      <MenuItem value={"none"}>None</MenuItem>
      <MenuItem value={"read"}>Read</MenuItem>
      <MenuItem value={"read-write"}>Read/Write</MenuItem>
    </Select>
  );
};

export default PermissionDropdown;
