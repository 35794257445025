import { Alert, Button, Stack, TextField } from '@mui/material';
import React from 'react';
import { useForm } from 'react-hook-form';

interface Inputs {
    email: string;
}

const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const validation = {
    email: {
        required: 'Email is required',
        pattern: {
            value: EMAIL_REGEX,
            message: 'Invalid email address'
        }
    }
}

const InviteUserPage: React.FC = () => {
    const { register, handleSubmit, formState: { errors } } = useForm<Inputs>();

    const onSubmit = async (data: Inputs) => {
        alert(data.email);
    };

    return <>
        <h1>Invite user</h1>
            <form>
                <Stack spacing={"15px"}>
                    <TextField {...register('email', validation.email)} id="email" name="email" label="Email" variant="outlined" />
                    {errors.email && <Alert severity='error'>{errors.email?.message}</Alert>}
                    <Button variant="contained" onClick={handleSubmit(onSubmit)}>Send Invite</Button>
                </Stack>
            </form>
    </>
};

export default InviteUserPage;