import { Button, TextField } from "@mui/material";
import { useState } from "react";
import { useToast } from "../../contexts/toastContext";
import { supabaseClient } from "../../util/supabase";
import { useNavigate } from "react-router";

const ChooseNewPassword = () => {
  const [newPassword, setNewPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const toast = useToast();

  const navigate = useNavigate();

  async function onSubmit() {
    if (newPassword.length < 8) {
      toast.error("Password must be at least 8 characters");
      return;
    }

    if (newPassword != repeatPassword) {
      toast.error("Both passwords must match");
      return;
    }

    const { data, error } = await supabaseClient.auth.updateUser({
      password: newPassword,
    });

    if (error != null) {
      toast.error("Something went wrong");
      console.error(error);
    }

    toast.success("Done!");

    setTimeout(() => {
      navigate("/");
    }, 1000);
  }

  return (
    <>
      <p>Please enter a new password that is at least 8 characters long</p>
      <TextField
        type="password"
        value={newPassword}
        onChange={(e) => setNewPassword(e.target.value)}
        placeholder="New password"
      ></TextField>
      <TextField
        type="password"
        value={repeatPassword}
        onChange={(e) => setRepeatPassword(e.target.value)}
        placeholder="Repeat password"
      ></TextField>
      <Button onClick={onSubmit} variant="contained">
        Submit
      </Button>
    </>
  );
};

export default ChooseNewPassword;
