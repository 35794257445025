import DirectoryModel from "../models/DirectoryModel";
import { supabaseClient } from "../util/supabase";
import { FileObject } from "@supabase/storage-js";
import StorageBucket from "../models/StorageBucket";
import { posix, updatePathSegment } from "../util/path";
import { emptyFolderPlaceholderName, recyclePathSegment } from "../constants";
import TextContent from "../models/TextContentModel";
import LinksContent from "../models/LinksContentModel";
import { SearchResultItem } from "./search";

const SUPABASE_EMPTY_FOLDER_PLACEHOLDER = ".emptyFolderPlaceholder";
const CHQ_EMPTY_FOLDER_PLACEHOLDER = ".ChqEmptyFolderPlaceholder";

export interface FileObjectWithSource extends FileObject {
  source: StorageBucket;
}

export async function getTopLevelDirectories(): Promise<FileObjectWithSource[] | undefined> {
  const { data: coreData, error: coreDataError } = await supabaseClient.storage
    .from("core-content")
    .list("");

  if (coreDataError) throw Error(JSON.stringify(coreDataError));

  const coreDataWithSource: FileObjectWithSource[] | undefined = coreData
    ?.filter((item) => item.name !== emptyFolderPlaceholderName)
    ?.filter((item) => item.name !== recyclePathSegment)
    .map((item) => {
      return { ...item, source: StorageBucket.CoreContent };
    });

  return coreDataWithSource;
}

interface DirectoriesModel {
  userData: FileObjectWithSource[];
  coreData: FileObjectWithSource[];
  isLeafNode: boolean;
}
export async function getDirectoriesForPath(
  path: string,
  companyId: string
): Promise<DirectoriesModel | null> {
  let dirModels: DirectoryModel[] = [];

  const coreContentPath = getCoreContentPath(path);

  console.log("from core-content");
  const { data: coreData, error: coreDataError } = await supabaseClient.storage
    .from("core-content")
    .list(coreContentPath);

  const userContentPath = getUserContentPath(path, companyId);

  const { data: userData, error: userDataError } = await supabaseClient.storage
    .from("user-content")
    .list(userContentPath);

  if (coreDataError || userDataError) {
    throw Error(coreDataError?.message || userDataError?.message);
  }

  let coreDataWithSource: FileObjectWithSource[] = [];
  let coreDataDirectories: string[] = [];
  if (coreData) {
    coreDataDirectories = coreData
      ?.filter((x) => {
        return !x.id; //directories have no id set
      })
      .map((x) => x.name); //return the name of all directories

    coreDataWithSource = coreData
      ?.filter((item) => item.name !== emptyFolderPlaceholderName)
      ?.filter((item) => item.name !== recyclePathSegment) //don't display recycle folder
      ?.map((x) => {
        return {
          ...x,
          source: StorageBucket.CoreContent,
        };
      });
  }

  let userDataWithSource: FileObjectWithSource[] = [];
  if (userData) {
    userDataWithSource = userData
      ?.filter((x) => {
        if (!x.id) {
          //is directory
          return !coreDataDirectories.includes(x.name); // only include if not core content
        } else {
          return true;
        }
      })
      ?.filter((item) => item.name !== recyclePathSegment) //don't display recycle folder
      .map((x) => {
        return {
          ...x,
          source: StorageBucket.UserContent,
        };
      });
  }

  let isLeafNode = true;

  if (coreData != null) {
    const coreFolders = coreData.filter((x) => x.id === null);
    if (coreFolders != null && coreFolders.length > 0) {
      isLeafNode = false; // this folder contains at least 1 sub folder so it's not a leaf node
    }
  }

  return {
    userData: userDataExclusions(userDataWithSource),
    coreData: userDataExclusions(coreDataWithSource),
    isLeafNode: isLeafNode,
  };
}

function userDataExclusions(userData: FileObjectWithSource[]) {
  const fileNameExclusions = [SUPABASE_EMPTY_FOLDER_PLACEHOLDER, CHQ_EMPTY_FOLDER_PLACEHOLDER];

  return userData.filter((x) => !fileNameExclusions.includes(x.name));
}

function getUserContentPath(path: string, companyId: string) {
  let userContentPath = path;
  if (path === "/") {
    userContentPath = companyId;
  } else {
    if (path.startsWith("/")) {
      userContentPath = path.substring(1);
    }
    userContentPath = `${companyId}/${userContentPath}`;
  }
  return userContentPath;
}

function getCoreContentPath(path: string) {
  let coreContentPath = "";
  if (path !== "/") {
    if (path.startsWith("/")) {
      coreContentPath = path.substring(1);
    }
  }
  return coreContentPath;
}

export async function getFileNamesAtPathRecursive(
  path: string,
  storageBucket: StorageBucket
): Promise<Set<string>> {
  let filePaths = new Set<string>();

  console.log("Test");
  const { data, error } = await supabaseClient.storage.from(storageBucket).list(path);

  if (error) {
    console.error(error);
    return filePaths;
  } else {
    if (data == null) return filePaths;

    for (const folderItem of data) {
      if (folderItem.id) {
        //item is a file
        filePaths.add(posix.join([path, folderItem.name]));
      } else {
        //item is a folder
        const items = await getFileNamesAtPathRecursive(
          posix.join([path, folderItem.name]),
          storageBucket
        );
        filePaths = new Set([...filePaths, ...items]);
      }
    }

    return filePaths;
  }
}

export async function updateNestedTextContent(oldPath: string, newPath: string) {
  const { data: matchingPathData, error } = await supabaseClient
    .from("text_content")
    .select()
    .like("path", `${oldPath}%`);

  if (error != null) {
    console.error(JSON.stringify(error));
    throw Error(error.message);
  }
  if (matchingPathData != null) {
    for (const record of matchingPathData as TextContent[]) {
      const updatedPath = updatePathSegment(record.path, oldPath, newPath);
      const { data, error } = await supabaseClient
        .from("text_content")
        .update({ path: updatedPath })
        .match({ id: record.id });
      if (error != null) {
        console.error(JSON.stringify(error));
      }
    }
  }
}

export async function updateNestedLinksContent(oldPath: string, newPath: string) {
  const { data: matchingPathData, error } = await supabaseClient
    .from("links_content")
    .select()
    .like("path", `${oldPath}%`);

  if (error != null) {
    console.error(JSON.stringify(error));
    throw Error(error.message);
  }
  if (matchingPathData != null) {
    for (const record of matchingPathData as LinksContent[]) {
      const updatedPath = updatePathSegment(record.path, oldPath, newPath);
      const { data, error } = await supabaseClient
        .from("links_content")
        .update({ path: updatedPath })
        .match({ id: record.id });
      if (error != null) {
        console.error(JSON.stringify(error));
      }
    }
  }
}

export async function getRecycleBinItems(
  storageBucket: StorageBucket,
  companyId: string | undefined
): Promise<string[]> {
  function filterNotPlaceholder(input: string): boolean {
    return !input.endsWith(emptyFolderPlaceholderName);
  }

  const path =
    storageBucket === StorageBucket.CoreContent
      ? recyclePathSegment
      : posix.join([companyId, recyclePathSegment]);


  let items: string[] = [];
  try {
    console.log("getRecycleBinItems");
    const itemsSet = await getFileNamesAtPathRecursive(path, storageBucket);
    items = Array.from(itemsSet);
  } catch (err) {
    console.error(JSON.stringify(err));
  }

  const filteredItems = items.filter(filterNotPlaceholder);
  return filteredItems;
}

export function getFolderPathsFromFilePaths(filePaths: Set<string>): Set<string> {
  const folderPaths = new Set<string>();

  filePaths.forEach((filePath) => folderPaths.add(posix.dirname(filePath)));

  return folderPaths;
}

export function fileObjectWithSourceFromSearchResultItem(
  searchResult: SearchResultItem,
  coreFile: boolean
): FileObjectWithSource {
  
  return {
    id: searchResult.id,
    name: searchResult.name,
    bucket_id: searchResult.bucket_id,
    owner: searchResult.owner,
    updated_at: searchResult.updated_at,
    created_at: searchResult.created_at,
    last_accessed_at: searchResult.last_accessed_at,
    metadata: searchResult.metadata,
    source: coreFile ? StorageBucket.CoreContent : StorageBucket.UserContent,
    buckets: {
      id: "",
      name: "",
      owner: "",
      created_at: "",
      updated_at: "",
      public: false,
    },
  };
}
