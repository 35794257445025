import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  styled,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { emptyFolderPlaceholderName } from "../constants";
import { ToastContext, useToast } from "../contexts/toastContext";
import {
  FileObjectWithSource,
  getFileNamesAtPathRecursive,
  getFolderPathsFromFilePaths,
} from "../data-access/directory";
import StorageBucket from "../models/StorageBucket";
import { getTrimmedBaseUrl, recycleFile } from "../util/files";
import { posix, stripCompanyIdFromPath } from "../util/path";

const StyledUnorderedList = styled("ul")`
  list-style-type: none;
`;

const RecycleFilesModal: React.FC<{
  fileObject: FileObjectWithSource;
  baseUrl: string[];
  storageBucket: StorageBucket;
  companyId?: string;
  open: boolean;
  handleCancel: () => void;
  handleRecycleComplete: () => void;
}> = ({
  fileObject,
  baseUrl,
  storageBucket,
  companyId,
  open,
  handleCancel,
  handleRecycleComplete,
}) => {
  const toast = useToast();
  const [paths, setPaths] = useState<Set<string>>(new Set<string>());
  const [folderPaths, setFolderPaths] = useState<Set<string>>(new Set<string>());
  const [recycling, setRecycling] = useState(false);

  let fullyQualifiedBaseUrl = "";
  if (storageBucket === StorageBucket.CoreContent) {
    const pathSegments = getTrimmedBaseUrl(baseUrl);
    fullyQualifiedBaseUrl = posix.join(pathSegments);
  } else {
    fullyQualifiedBaseUrl = posix.join([companyId, ...baseUrl]);
  }

  useEffect(() => {
    async function assembleArrayOfFiles() {
      let filePathSet = new Set<string>([]);
      if (fileObject.id) {
        const filePath = posix.join([fullyQualifiedBaseUrl, fileObject.name]);
        filePathSet = new Set<string>([filePath]);
        setPaths(filePathSet);
      } else {
        const filePath = posix.join([fullyQualifiedBaseUrl, fileObject.name]);
        console.log("RecycleFilesModal");
        const filePaths = await getFileNamesAtPathRecursive(filePath, storageBucket);
        setPaths(filePaths);
        const folderPaths = getFolderPathsFromFilePaths(filePaths);
        setFolderPaths(folderPaths);
      }
    }

    assembleArrayOfFiles();
  }, []);

  async function onConfirmRecycle() {
    setRecycling(true);
    for (const filePath of paths) {
      try {
        await recycleFile(filePath, fileObject.source, companyId);
        toast?.success(`Recycled: ${filePath}`);
      } catch (err) {
        console.error(err);
        toast?.error(`Failed to recycle: ${filePath}`);
      }
    }

    setRecycling(false);
    handleRecycleComplete();
  }

  let pathsForDisplay = Array.from(paths);

  if (storageBucket === StorageBucket.UserContent && companyId != null) {
    pathsForDisplay = Array.from(paths).map((path) => stripCompanyIdFromPath(path, companyId));
  }

  return (
    <Dialog open={open} onClose={handleCancel}>
      <DialogTitle>Are you sure you want to delete these files?</DialogTitle>
      <DialogContent>
        <p>You can retrieve them later from the recycle bin if required</p>
        <StyledUnorderedList>
          {pathsForDisplay
            .filter((filePath) => !filePath.endsWith(emptyFolderPlaceholderName))
            .map((filePath, i) => (
              <li key={i}>{filePath}</li>
            ))}
        </StyledUnorderedList>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} variant={"outlined"} disabled={recycling}>
          Cancel
        </Button>
        <Button
          onClick={onConfirmRecycle}
          color={"error"}
          variant={"contained"}
          disabled={recycling}
        >
          Yes, recycle them
          {recycling && <CircularProgress></CircularProgress>}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RecycleFilesModal;
