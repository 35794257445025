import StoragePermissionModel from "../../../models/StoragePermissionModel";
import { supabaseClient } from "../../../util/supabase";

export async function getStoragePermissionsForCompanyUsers(companyId: string) {
  const { data, error } = await supabaseClient
    .from("storage_permission")
    .select()
    .eq("company_id", companyId);

  if (error != null) {
    console.error(error);
    throw Error(error.message);
  }

  return data as StoragePermissionModel[];
}

export async function deletePermissionForUser(permission: StoragePermissionModel) {
  const { data, error } = await supabaseClient
    .from("storage_permission")
    .delete()
    .eq("id", permission.id);

  if (error != null) {
    console.error(error);
    throw Error(error.message);
  }
}

export async function insertPermissionForUser(permission: StoragePermissionModel) {
  const { data, error } = await supabaseClient.from("storage_permission").insert(permission);

  if (error != null) {
    console.error(error);
    throw Error(error.message);
  }
}
