import { Breadcrumbs, Link, styled, Typography } from "@mui/material";
import React from "react";
import { useLocation } from "react-router";
import { Link as RouterLink } from "react-router-dom";
import StyledHr from "./StyledHr";
import HomeIcon from "@mui/icons-material/Home";

const LinkRouter = (props: any) => <Link {...props} component={RouterLink} />;

interface NavBreadcrumbProps {}
const NavBreadcrumb: React.FC<NavBreadcrumbProps> = () => {
  const location = useLocation();
  const pathnames = location.pathname.split("/").filter((x) => x);
  if (pathnames.length < 2) {
    return null;
  }
  return (
    <>
      <Breadcrumbs aria-label="breadcrumb">
        {pathnames.map((value, index) => {
          const last = index === pathnames.length - 1;
          const to = `/${pathnames.slice(0, index + 1).join("/")}`;

          if (index === 0)
            return (
              <LinkRouter key={index} underline="hover" to={to}>
                <HomeIcon></HomeIcon>
              </LinkRouter>
            );

          return last ? (
            <Typography color="text.primary" key={to}>
              {decodeURI(value)}
            </Typography>
          ) : (
            <LinkRouter underline="hover" color="inherit" to={to} key={to}>
              {decodeURI(value)}
            </LinkRouter>
          );
        })}
      </Breadcrumbs>
      <StyledHr />
    </>
  );
};

export default NavBreadcrumb;
