import {
  Box,
  Button,
  ButtonGroup,
  Card,
  List,
  ListItem,
  ListItemButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import AdminOnly from "../components/AdminOnly";
import CompanyDetails, { NoCompanySelected } from "../features/admin/components/CompanyDetails";
import { getCompanies } from "../features/admin/data-access/admin";

type Company = {
  id: string;
  name: string;
  created_at: string;
};

type CompaniesListProps = {
  companies?: Company[];
  onCompanyClick: (companyId: Company) => void;
};

const CompaniesList = ({ companies, onCompanyClick }: CompaniesListProps) => {
  return (
    <Stack
      direction={"column"}
      sx={{ padding: "10px", minWidth: "300px", borderRight: "1px solid grey" }}
    >
      <h3>Companies</h3>
      <List>
        {companies &&
          companies.map((company) => {
            return (
              <ListItemButton key={company.id} onClick={() => onCompanyClick(company)}>
                {company.name}
              </ListItemButton>
            );
          })}
      </List>
    </Stack>
  );
};

const AdminSettingsPage = () => {
  const [selectedCompany, setSelectedCompany] = useState<Company | undefined>(undefined);

  const { data: companies, error, isLoading } = useQuery(["companies"], () => getCompanies());

  function onCompanyClick(company: Company) {
    setSelectedCompany(company);
  }

  if (isLoading) return <span>Loading...</span>;

  if (error != null) return <span style={{ color: "red" }}>Something went wrong</span>;

  return (
    <Paper>
      <Stack direction={"row"}>
        <CompaniesList companies={companies || []} onCompanyClick={onCompanyClick}></CompaniesList>
        {selectedCompany && <CompanyDetails selectedCompany={selectedCompany}></CompanyDetails>}
        {!selectedCompany && <NoCompanySelected></NoCompanySelected>}
      </Stack>
    </Paper>
  );
};

export default AdminSettingsPage;
