import React from "react";
import { FileObject } from "@supabase/storage-js";
import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import { getFileExtensionFromName } from "../util/files";
import filenames from "../filenames";

interface Filenames {
  [code: string] : string;
}

const filenamesObj = filenames as Filenames;

interface FileIconDisplayProps {
  file: Pick<FileObject, "id" | "name">;
}
const FileIconDisplay: React.FC<FileIconDisplayProps> = ({ file }) => {

  function folderHasCode(folderName: string) {
    const nameSegments = folderName.split(" ");
    if (nameSegments.length > 0) {
      const codeSegments = nameSegments[0].split(".");
      if (codeSegments.length > 0 && !isNaN(+codeSegments[0])) {
        return true;
      }
    }
    return false;
  }

  let pathCode = "";

  if (!file.id && folderHasCode(file.name)) {
    pathCode = file.name.split(" ")?.[0];
  }


  if (!file.id) {
    if (pathCode !== "") {
      const fileName = filenamesObj[pathCode];
      if (fileName != null) {
        const filePath = `/img/folder_icons/${fileName}`;
        return <img width="50" height="50" src={filePath}></img>
      }
    }
    return <FolderOpenIcon></FolderOpenIcon>;
  } else {
    const fileExtension = getFileExtensionFromName(file.name);
    switch (fileExtension) {
      case "pdf":
        return <i className={"fa fa-file-pdf-o"}></i>;
      case "docx":
        return <i className={"fa fa-file-word-o"}></i>;
      case "doc":
        return <i className={"fa fa-file-word-o"}></i>;
      case "xlsx":
        return <i className={"fa fa-file-excel-o"}></i>;
      case "xls":
        return <i className={"fa fa-file-excel-o"}></i>;
      case "png":
        return <i className={"fa fa-file-image-o"}></i>;
      default:
        return <i className={"fa fa-file-o"}></i>;
    }
  }
};

export const FileIconDisplayNameOnly: React.FC<{ fileName: string }> = ({ fileName }) => {
  return <FileIconDisplay file={{ name: fileName, id: "1" }}></FileIconDisplay>;
};

export default FileIconDisplay;
