import { Card, Grid } from "@mui/material";
import React from "react";
import { FileObjectWithSource } from "../data-access/directory";
import StorageBucket from "../models/StorageBucket";
import { posix } from "../util/path";
import AdminOnly from "./AdminOnly";
import { CategoryTile, StyledGrid } from "./BusinessCategoriesList";
import FileUpload from "./FileUpload";
import RecycleBinModal from "./recycle-bin/RecycleBinModal";
import { sortFilesByVersionNumbers } from "../util/files";

interface Props {
  dirs?: FileObjectWithSource[];
  baseUrl: string[];
  reloadData: () => void;
}
const SubcategoryTiles: React.FC<Props> = ({ dirs, baseUrl, reloadData }) => {
  const docsPrefix = baseUrl?.[0] === "docs" ? [] : ["/docs"];

  const dirsSorted = sortFilesByVersionNumbers(dirs || []);

  return (
    <Card sx={{ position: "relative" }}>
      <StyledGrid container direction={"row"} mb={5} alignContent={"flex-start"} spacing={4}>
        {dirsSorted &&
          dirsSorted.map((dir, i) => {
            const url = posix.join([...docsPrefix, ...baseUrl]);
            return (
              <Grid item xs={3} key={i}>
                <CategoryTile dir={dir} baseUrl={url} reload={reloadData}></CategoryTile>
              </Grid>
            );
          })}
      </StyledGrid>
      <AdminOnly>
        <div style={{ position: "absolute", top: "0", right: "0", padding: "20px" }}>
          <FileUpload
            currentPath={baseUrl}
            storageBucket={StorageBucket.CoreContent}
            reloadData={reloadData}
          ></FileUpload>
        </div>
        <div>
          <RecycleBinModal
            storageBucket={StorageBucket.CoreContent}
            companyId={""}
          ></RecycleBinModal>
        </div>
      </AdminOnly>
    </Card>
  );
};

export default SubcategoryTiles;
