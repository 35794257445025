import { Button, Stack, Tooltip } from "@mui/material";
import { useMutation, useQuery } from "react-query";
import { deleteFavourite, getUserFavourites } from "../../data-access/user-favourites";
import AddFavouriteModal from "./AddFavouriteModal";
import UserFavouriteModel from "../../models/UserFavouriteModel";
import DeleteIcon from "@mui/icons-material/Delete";
import { useToast } from "../../contexts/toastContext";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

const UserFavourites = () => {
  const { data, isLoading, error, refetch } = useQuery(["user-favourites"], getUserFavourites);

  const toast = useToast();

  const deleteMutation = useMutation({
    mutationFn: deleteFavourite,
    onSuccess: () => {
      refetch();
    },
    onError: () => {
      toast?.error("Something went wrong");
    },
  });

  async function onDelete(favourite: UserFavouriteModel) {
    await deleteMutation.mutate(favourite);
  }

  if (error != null) {
    return <span>Something went wrong</span>;
  }

  if (isLoading) {
    return <span>Loading...</span>;
  }

  return (
    <Stack direction="column">
      <Stack direction="row" justifyContent={"space-between"} alignItems={"center"}>
        <Stack direction="row" alignItems="center" justifyContent="flex-start" gap={1}>
          <h3>Favourites</h3>
          <Tooltip title="Add links to web pages you use frequently as part of your role with your company">
            <HelpOutlineIcon sx={{ color: "lightgrey" }}></HelpOutlineIcon>
          </Tooltip>
        </Stack>
        <AddFavouriteModal reload={refetch}></AddFavouriteModal>
      </Stack>
      {data?.map((fav) => (
        <Stack key={fav.id} direction="row" justifyContent={"space-between"} alignItems={"center"}>
          <a href={fav.link_url} target="_blank">
            {fav.link_label}
          </a>
          <Button onClick={(e) => onDelete(fav)} size={"small"}>
            <DeleteIcon sx={{ color: "#CCC" }}></DeleteIcon>
          </Button>
        </Stack>
      ))}
    </Stack>
  );
};

export default UserFavourites;
