import { Box, Stack, styled } from "@mui/material";
import React from "react";
import { SearchResultItem } from "../../data-access/search";
import SearchItem from "./SearchItem";

const SearchResultsContainer = styled(Box)`
  position: absolute;
  top: 50px;
  background-color: #fff;
  width: 700px;
  margin: 5px;
  padding: 10px;
  border-radius: 0px 0px 5px 5px;
  color: #000;
  box-shadow: 7px 5px 12px -1px rgba(0, 0, 0, 0.75);
  max-height: 400px;
  overflow-y: scroll;
  & ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
`;

const SearchResults: React.FC<{
  coreFiles: SearchResultItem[];
  yourFiles: SearchResultItem[];
  onSearchResultItemClicked: (item: SearchResultItem) => void;
  onSearchResultFolderClicked: (item: SearchResultItem) => void;
}> = ({ coreFiles, yourFiles, onSearchResultItemClicked, onSearchResultFolderClicked }) => {
  return (
    <SearchResultsContainer>
      <Stack direction={"column"}>
        {coreFiles?.length > 0 && (
          <>
            <b>From CompanyHQ</b>
            <ul>
              {coreFiles.map((resultItem) => (
                <li key={resultItem.id}>
                  <SearchItem
                    searchResultItem={resultItem}
                    onClick={onSearchResultItemClicked}
                    onFolderClick={onSearchResultFolderClicked}
                  ></SearchItem>
                </li>
              ))}
            </ul>
          </>
        )}
        {yourFiles?.length > 0 && (
          <>
            <b>Your Files</b>
            <ul>
              {yourFiles.map((resultItem) => (
                <li key={resultItem.id}>
                  <SearchItem
                    searchResultItem={resultItem}
                    onClick={onSearchResultItemClicked}
                    onFolderClick={onSearchResultFolderClicked}
                  ></SearchItem>
                </li>
              ))}
            </ul>
          </>
        )}
      </Stack>
    </SearchResultsContainer>
  );
};

export default SearchResults;
