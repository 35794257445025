import { supabaseClient } from "../util/supabase";
import { trimSlashes } from "../util/path";

export enum RichTextRepoType {
  MainContent = "text_content",
  LinksContent = "links_content",
}

export interface TextContentRecord {
  id?: number;
  path?: string;
  content?: string;
}

export async function getTextContentForPath(
  path: string,
  type: RichTextRepoType
): Promise<TextContentRecord | undefined> {
  let storagePath = trimSlashes(path);
  let { data, error } = await supabaseClient
    .from(type)
    .select("id, path, content")
    .eq("path", storagePath);

  if (error) {
    console.error(error);
    throw new Error(error.message);
  }

  if (!data || data.length < 1) {
    return undefined;
  } else {
    return data[0] as TextContentRecord;
  }
}

export async function upsertTextContentForPath(
  path: string,
  jsonContent: string,
  type: RichTextRepoType,
  id?: number
): Promise<TextContentRecord[] | null> {
  if (id !== null && id !== undefined) {
    const updatedRecord = await supabaseClient
      .from(type)
      .update({ content: jsonContent })
      .match({ id: id });
    return updatedRecord.data;
  } else {
    const storagePath = trimSlashes(path);
    const newRecord = await supabaseClient.from(type).insert({
      path: storagePath,
      content: jsonContent,
    });
    return newRecord.data;
  }
}

export async function deleteTextContentForPath(path: string, type: RichTextRepoType) {
  const { data, error } = await supabaseClient.from(type).delete().match({ path: path });

  if (error != null) throw Error(JSON.stringify(error));
}
