import React from "react";
import { Routes, Route, BrowserRouter as Router } from "react-router-dom";
import Home from "./pages/HomePage";
import MainLayout from "./components/MainLayout";
import DocsPage from "./pages/DocsPage";
import LoginPage from "./pages/LoginPage";
import InviteUserPage from "./pages/InviteUserPage";
import SetPasswordPage from "./pages/SetPasswordPage";
import SearchResults from "./pages/SearchResults";
import { createTheme, CssBaseline, ThemeProvider } from "@mui/material";
import { ToastContextProvider } from "./contexts/toastContext";
import ResetPasswordPage from "./pages/ResetPasswordPage";
import { DocumentViewerContextProvider } from "./contexts/DocumentViewerContext";
import ForgotPassword from "./pages/ForgotPassword";
import AdminSettingsPage from "./pages/AdminSettingsPage";

import { QueryClient, QueryClientProvider } from "react-query";
import ForgotPassword2 from "./pages/ForgotPassword2";

const theme = createTheme({
  palette: {
    background: {
      default: "#73808A",
    },
    primary: {
      main: "#00263A",
      light: "#73808A",
    },
    secondary: {
      main: "#C2A01E",
      light: "#D9C57A",
    },
  },
});

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

const DefaultPage: React.FC = () => {
  return <h1>Page not found</h1>;
};

const DirectoryPage: React.FC = () => {
  return <h1>Directory</h1>;
};

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <ToastContextProvider>
        <ThemeProvider theme={theme}>
          <DocumentViewerContextProvider>
            <CssBaseline></CssBaseline>
            <Router>
              <Routes>
                <Route
                  path={"/forgotpassword"}
                  element={<ForgotPassword2></ForgotPassword2>}
                ></Route>
                <Route path="/resetpassword" element={<ResetPasswordPage />}></Route>
                <Route path="/setpassword" element={<SetPasswordPage />}></Route>
                <Route path="/login" element={<LoginPage />}></Route>
                <Route path="/" element={<MainLayout />}>
                  <Route index element={<Home />}></Route>
                  <Route path="search" element={<SearchResults />}></Route>
                  <Route path="invite" element={<InviteUserPage />}></Route>
                  <Route path="docs/*" element={<DocsPage />}></Route>
                  <Route path="admin-settings" element={<AdminSettingsPage />}></Route>
                </Route>
                <Route path="*" element={<DefaultPage />} />
              </Routes>
            </Router>
          </DocumentViewerContextProvider>
        </ThemeProvider>
      </ToastContextProvider>
    </QueryClientProvider>
  );
}

export default App;
