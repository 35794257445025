import React from "react";
import { Grid, Paper, Stack } from "@mui/material";
import BusinessCategoriesList from "../components/BusinessCategoriesList";
import UserFavourites from "../components/favourites/UserFavourites";
import BlogRss from "../features/dashboard/BlogRss";
import RequiredDocumentsSummary from "../features/required-documents/RequiredDocumentsSummary";

const Home: React.FC = () => {
  return (
    <>
      <Grid container>
        <Grid item xs={8}>
          <Paper sx={{ height: "100%", padding: "5px 20px" }}>
            <h3>Business Categories</h3>
            <BusinessCategoriesList></BusinessCategoriesList>
          </Paper>
        </Grid>
        <Grid item xs={4}>
          <Paper sx={{ height: "100%", padding: "5px 20px", marginLeft: "10px" }}>
            <UserFavourites></UserFavourites>
            <h3>Recently Accessed Documents</h3>
            <Stack
              direction={"column"}
              spacing={2}
              justifyContent="flexStart"
              alignItems="left"
              mb={3}
            >
              <a href="#">Types of Business Insurance</a>
              <a href="#">Brokers & Insurers</a>
              <a href="#">Insurance Policies</a>
            </Stack>
          </Paper>
        </Grid>
        <Grid item xs={8} sx={{ marginTop: "10px" }}>
          <Paper sx={{ height: "100%", padding: "5px 20px" }}>
            <BlogRss></BlogRss>
          </Paper>
        </Grid>
        <Grid item xs={4} sx={{ marginTop: "10px" }}>
          <Paper sx={{ height: "100%", padding: "5px 20px", marginLeft: "10px" }}>
            <RequiredDocumentsSummary></RequiredDocumentsSummary>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default Home;
