import { Checkbox, Table, TableBody, TableCell, TableRow, Alert } from "@mui/material";
import React, { useState } from "react";
import StorageBucket from "../../models/StorageBucket";
import DescriptionIcon from "@mui/icons-material/Description";
import { stripCompanyIdFromPath } from "../../util/path";

export interface RecycleBinItemViewModel {
  fullPath: string;
  displayPath: string;
  selected: boolean;
}

const RecycleBinItems: React.FC<{
  storageBucket: StorageBucket;
  companyId: string;
  allRecycleBinItems: RecycleBinItemViewModel[];
  selectedRecycleBinItems: string[];
  setSelectedRecycleBinItems: (items: string[]) => void;
}> = ({
  storageBucket,
  companyId,
  allRecycleBinItems,
  selectedRecycleBinItems,
  setSelectedRecycleBinItems,
}) => {
  const [loading, setLoading] = useState<boolean>(false);

  let recyclePathArray: RecycleBinItemViewModel[] = [];

  if (storageBucket === StorageBucket.UserContent) {
    recyclePathArray = Array.from(allRecycleBinItems).map((item) => {
      const pathWithoutCompanyId = stripCompanyIdFromPath(item.displayPath, companyId);
      return { ...item, displayPath: pathWithoutCompanyId };
    });
  }
  recyclePathArray = Array.from(allRecycleBinItems);

  function handleCheckedChange(checked: boolean, fullPath: string) {
    let updatedItems = [...selectedRecycleBinItems];
    if (checked) {
      updatedItems.push(fullPath);
    } else {
      const index = updatedItems.indexOf(fullPath);
      updatedItems.splice(index, 1);
    }

    setSelectedRecycleBinItems(updatedItems);
  }

  return (
    <>
      {loading && <span>Loading...</span>}
      {!loading && allRecycleBinItems.length === 0 && (
        <Alert severity={"info"}>No recycled items</Alert>
      )}
      {allRecycleBinItems && (
        <Table>
          <TableBody>
            {recyclePathArray.map((item) => (
              <TableRow key={item.fullPath}>
                <TableCell
                  sx={{ display: "flex", alignItems: "center", justifyContent: "left", gap: "2" }}
                >
                  <Checkbox
                    checked={selectedRecycleBinItems.includes(item.fullPath)}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      handleCheckedChange(event.target.checked, item.fullPath)
                    }
                  ></Checkbox>
                  <DescriptionIcon></DescriptionIcon> {item.displayPath}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
    </>
  );
};

export default RecycleBinItems;
